import storage from "@/utils/storageUtil";
import api from "@/api";
const { DateTime } = require("luxon");

let waitingToSetActiveModel = false;
let previousModel = "";

// used to maintain prompt group selections from localstorage
const PREVIOUS_PROMPT_GROUP_SELECTIONS = null;

const state = () => ({
  initialized: false,
  aiEngineModels: [],
  aiPromptTemplates: [],
  terms: [],
  activeModels: [],
  aiMode: "summary",
  markupVisible: true,
  aiPromptGroup: null,
  aiPromptGroups: [],
  aiPeers: [],
  selectedAiPeers: [],
  aiTag: "",
  aiEngineModel: "",
  aiModel: "all",
  aiResultset: "models",
  peerHistory: false,
  selectedTimeRange: getDefaultTimeRange(),
  selectedDate: new Date(),
  selectedPrompt: null,
  selectedTab: "summaries",
  showSentiment: true,
  showTags: true,
  userInfo: {},
  neutralThreshold: 5,
  openResponses: [0],
  maxPrompts: 5,
  maxPromptGroups: 10,
  aiReports: [],
  pollingInterval: null, // Add pollingInterval to the state
});

const getDefaultTimeRange = () => {
  const now = DateTime.now();
  return [now.minus({ days: 6 }).toJSDate(), now.toJSDate()];
};

const getters = {
  aiEngineModels: (state) => state.aiEngineModels,
  aiPromptTemplates: (state) => state.aiPromptTemplates,
  terms: (state) => state.terms,
  aiMode: (state) => state.aiMode,
  markupVisible: (state) => state.markupVisible,
  aiPromptGroup: (state) => state.aiPromptGroup,
  aiPromptGroups: (state) => state.aiPromptGroups,
  selectedAiPeers: (state) => state.selectedAiPeers,
  aiTag: (state) => state.aiTag,
  aiEngineModel: (state) => state.aiEngineModel,
  activeModels: (state) => state.activeModels,
  aiModel: (state) => state.aiModel,
  aiResultset: (state) => state.aiResultset,
  isAiViewHistory: (state) => state.aiResultset === "history",
  peerHistory: (state) => state.peerHistory,
  selectedTimeRange: (state) => state.selectedTimeRange,
  selectedDate: (state) => state.selectedDate,
  selectedPrompt: (state) => state.selectedPrompt,
  selectedTab: (state) => state.selectedTab,
  showSentiment: (state) => state.showSentiment,
  showTags: (state) => state.showTags,
  timeRangeOptions: (state) => state.timeRangeOptions,
  userInfo: state => state.userInfo,
  neutralThreshold: state => state.neutralThreshold,
  openResponses: state => state.openResponses,
  maxPrompts: (state) => state.maxPrompts,
  maxPromptGroups: (state) => state.maxPromptGroups,
  aiReports: (state) => state.aiReports,
};

const actions = {

  fetchAIReportStatus({ commit }, { clientId, notifier, shouldNotify = false }) {
    return api.getAIReportStatus(clientId)
      .then((result) => {
        if (result && result.data) {
          // Commit the data to the state if needed
          commit('SET_AI_REPORTS', result.data);

          if (shouldNotify) {
            // Check each record in the array
            result.data.forEach(record => {
              if (record.status === 'STATE_COMPLETED') {
                const updateDate = new Date(record.update_date);
                const now = new Date();
                const timeDifference = now - updateDate;
                const oneMinute = 60 * 1000; // 1 minute in milliseconds

                if (timeDifference < oneMinute) {
                  if (notifier) {
                    notifier.info({
                      message: `View your Analysis Report on ${record.ai_prompt_group_display_name} - now available in Reports.`,
                      duration: 5000
                    });
                  }
                }
              }
            });
          }

        }
      })
      .catch((err) => {
        console.error("Error fetching AI report status:", err);
      });
  },


  loadUserInfo({ commit }) {
    return api.getUserInfo()
      .then((results) => {
        commit('SET_USER_INFO', results.data);
        return results.data;
      })
  },

  fetchPromptGroups({ commit, dispatch, state }) {
    return new Promise((resolve, reject) => {
      if (state.userInfo && state.userInfo.client && state.userInfo.client.client_id) {
        const client_id = state.userInfo.client.client_id;
        api.getPromptGroupsForClient(client_id)
          .then((result) => {
            if (result && result.data) {
            commit('SET_AI_PROMPT_GROUPS', result.data);
            dispatch('setAppropriatePromptGroup');
                    resolve(result);
            } else {
              reject("Did not receive data");
            }
          })
          .catch((err) => {
            reject(err);
          });
      } else {
        reject("Client ID is not available");
      }

    });
  },
  
  fetchAccountMax({ commit, state }) {
    return new Promise((resolve, reject) => {
      if (state.userInfo && state.userInfo.client && state.userInfo.client.client_id) {
        const client_id = state.userInfo.client.client_id;
        api.getAccountMax(client_id)
          .then((result) => {
            if (result && result.data) {
              commit('SET_MAX_PROMPT_GROUPS', result.data.maxPromptGroups);
              commit('SET_MAX_PROMPTS', result.data.maxPrompts);
              resolve(result);
            } else {
              reject("Did not receive data");
            }
          })
          .catch((err) => {
            reject(err);
          });
      } else {
        reject("Client ID is not available");
      }
    });
  },

  setAppropriatePromptGroup({ commit, state }) {
    if (!state.aiPromptGroups.length) {
      return;
    }
  
    if (!state.aiPromptGroup) {
      const defaultPromptGroup = state.aiPromptGroups[0];
      const promptGroupSelections = localStorage.getItem(PREVIOUS_PROMPT_GROUP_SELECTIONS);
  
      if (promptGroupSelections && state.userInfo) {
        const [firstlast, promptGroupId] = promptGroupSelections.split(':');
        const userFullName = `${state.userInfo.first_name}${state.userInfo.last_name}`;
  
        if (firstlast === userFullName) {
          const promptGroup = state.aiPromptGroups.find(group => group.id === +promptGroupId);
          commit('SET_AI_PROMPT_GROUP', promptGroup);
          return;
        }
      }
  
      // If no matching promptGroup is found, set the default one
      commit('SET_AI_PROMPT_GROUP', defaultPromptGroup);
    }
  },

  initializeAI(o, { notifier }) {
    const { commit, state, dispatch } = o;

    if (state.initialized) {
      return Promise.resolve();
    }

    return Promise.all([
      api.getAiEngineModels().then((res) => {
        if (res?.data.length > 0) {
          const engineModels = res.data;
          commit('SET_AI_ENGINE_MODELS', engineModels);
          commit('SET_AI_ENGINE_MODEL', engineModels[0]);
        }
      }),
      api.getAiPromptTemplates().then((res) => {
        if (res?.data.length > 0) {
          commit('SET_AI_PROMPT_TEMPLATES', res.data);
        }
      })
    ]).then(() => {
      return actions.loadUserInfo(o);
    }).then(() => {
      commit('SET_INITIALIZED', true);
      return actions.fetchPromptGroups(o);
    }).then(() => {
      // Start polling after initialization with the clientId and notifier
      const clientId = state.userInfo.client.client_id;
      dispatch('startPolling', { clientId, notifier });

      // Set the appropriate prompt group
      dispatch('setAppropriatePromptGroup');
    });
  },

  setNeutralThreshold({ commit }, payload) {
    commit('SET_NEUTRAL_THRESHOLD', payload);
  },
  setUserInfo({ commit }, userInfo) {
    commit('SET_USER_INFO', userInfo);
  },
  setTerms({ commit }, terms) {
    commit("SET_TERMS", terms);
  },
  setAiMode({ commit }, aiMode) {
    commit("SET_AI_MODE", aiMode);
  },
  setMarkupVisible({ commit }, markupVisible) {
    commit("SET_MARKUP_VISIBLE", markupVisible);
  },
  setAiPromptGroup({ commit, state }, payload) {
    if (state.userInfo) {
      const selections = `${state.userInfo.first_name}${state.userInfo.last_name}:${payload.id}`;
      localStorage.setItem(PREVIOUS_PROMPT_GROUP_SELECTIONS, selections);
      commit("SET_AI_PROMPT_GROUP", payload);
      commit("SET_SELECTED_PROMPT", null);
    }
  },
  setSelectedAiPeers({ commit }, payload) {
    commit("SET_SELECTED_AI_PEERS", payload);
  },
  setAiEngineModels({ commit }, payload) {
    commit("SET_AI_ENGINE_MODELS", payload);
  },
  setAiTag({ commit }, payload) {
    commit("SET_AI_TAG", payload);
  },
  setAiEngineModel({ commit }, payload) {
    commit("SET_AI_ENGINE_MODEL", payload);
  },
  setActiveModels({ commit }, activeModels) {
    commit("SET_ACTIVE_MODELS", activeModels);
    if (waitingToSetActiveModel) {
      commit("SET_AI_MODEL", activeModels[0]);
      waitingToSetActiveModel = false;
    }
  },
  setAiModel({ commit, state }, payload) {
    commit("SET_AI_MODEL", payload);
    if (payload === "all") {
      commit("SET_AI_RESULTSET", "models");
    } else {
      previousModel = payload;
      if (state.aiResultset === "models") {
        commit("SET_AI_RESULTSET", "history");
      }
    }
  },
  setAiResultset({ commit, state }, payload) {
    commit("SET_AI_RESULTSET", payload);
    if (payload === "models") {
      commit("SET_AI_MODEL", "all");
    } else if (state.aiModel === "all") {
      const newModel = previousModel || state.activeModels[0];
      waitingToSetActiveModel = !newModel;
      if (newModel) {
        commit("SET_AI_MODEL", newModel);
      }
    }
  },
  setPeerHistory({ commit }, peerHistory) {
    commit("SET_PEER_HISTORY", peerHistory);
  },
  setSelectedTimeRange({ commit }, selectedTimeRange) {
    commit("SET_SELECTED_TIMERANGE", selectedTimeRange);
  },
  setSelectedDate({ commit }, selectedDate) {
    commit("SET_SELECTED_DATE", selectedDate);
  },
  setSelectedPrompt({ commit }, selectedPrompt) {
    commit("SET_SELECTED_PROMPT", selectedPrompt);
  },
  setSelectedTab({ commit }, selectedTab) {
    commit("SET_SELECTED_TAB", selectedTab);
  },
  setShowSentiment({ commit }, showSentiment) {
    commit("SET_SHOW_SENTIMENT", showSentiment);
  },
  setShowTags({ commit }, showTags) {
    commit("SET_SHOW_TAGS", showTags);
  },
  setAIReports({ commit }, aiReports) {
    commit("SET_AI_REPORTS", aiReports);
  },
  toggleResponseOpen({ commit }, responseIndex) {
    commit("TOGGLE_RESPONSE_OPEN", responseIndex);
  },
  setOpenResponses({ commit }, openResponses) {
    commit("SET_OPEN_RESPONSES", openResponses);
  },

  startPolling({ dispatch }, { clientId, notifier }) {
    dispatch('fetchAIReportStatus', { clientId, notifier, shouldNotify: true });
  
    this.pollingInterval = setInterval(() => {
      dispatch('fetchAIReportStatus', { clientId, notifier, shouldNotify: true });
    }, 60000);
  },
  

  stopPolling() {
    if (this.pollingInterval) {
      clearInterval(this.pollingInterval);
      this.pollingInterval = null;
    }
  },
};

const mutations = {
  SET_NEUTRAL_THRESHOLD(state, payload) {
    state.neutralThreshold = payload;
  },
  SET_AI_ENGINE_MODELS(state, payload) {
    state.aiEngineModels = payload;
  },
  SET_AI_PROMPT_TEMPLATES(state, payload) {
    state.aiPromptTemplates = payload;
  },
  SET_INITIALIZED(state, payload) {
    state.initialized = payload;
  },
  SET_TERMS(state, payload) {
    state.terms = payload;
  },
  SET_AI_MODE(state, payload) {
    state.aiMode = payload;
    storage.set("aiMode", payload);
  },
  SET_MARKUP_VISIBLE(state, payload) {
    state.markupVisible = payload;
  },
  SET_AI_PROMPT_GROUP(state, payload) {
    state.aiPromptGroup = payload;
  },
  SET_AI_PROMPT_GROUPS(state, payload) {
    state.aiPromptGroups = payload;
  },
  SET_MAX_PROMPTS(state, payload) {
    state.maxPrompts = payload;
  },
  SET_MAX_PROMPT_GROUPS(state, payload) {
    state.maxPromptGroups = payload;
  },
  SET_AI_REPORTS(state, payload) {
    state.aiReports = payload;
  },
  SET_SELECTED_AI_PEERS(state, payload) {
    state.selectedAiPeers = payload;
  },
  SET_AI_TAG(state, payload) {
    state.aiTag = payload;
  },
  SET_AI_ENGINE_MODEL(state, payload) {
    state.aiEngineModel = payload;
  },
  SET_AI_RESULTSET(state, payload) {
    state.aiResultset = payload;
  },
  SET_ACTIVE_MODELS(state, payload) {
    state.activeModels = payload;
  },
  SET_AI_MODEL(state, payload) {
    state.aiModel = payload;
  },
  SET_PEER_HISTORY(state, payload) {
    state.peerHistory = payload;
  },
  SET_SELECTED_TIMERANGE(state, payload) {
    state.selectedTimeRange = payload;
  },
  SET_SELECTED_DATE(state, payload) {
    state.selectedDate = payload;
  },
  SET_SELECTED_PROMPT(state, payload) {
    state.selectedPrompt = payload;
  },
  SET_SELECTED_TAB(state, payload) {
    state.selectedTab = payload;
  },
  SET_SHOW_SENTIMENT(state, payload) {
    state.showSentiment = payload;
  },
  SET_SHOW_TAGS(state, payload) {
    state.showTags = payload;
  },
  SET_USER_INFO(state, payload) {
    state.userInfo = payload;

  },
  TOGGLE_RESPONSE_OPEN(state, responseIndex) {
    const placeInArray = state.openResponses.indexOf(responseIndex);
    if (placeInArray === -1) {
      state.openResponses.push(responseIndex);
    } else {
      state.openResponses.splice(placeInArray, 1);
    }
  },
  SET_OPEN_RESPONSES(state, openResponses) {
    state.openResponses = openResponses;
  },
  SET_SOME_DATA(state, payload) {
    // Handle the data as needed
    state.someData = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
