import axios from 'axios';
import router from '@/router';
import appConfig from '@/../config/config.json';
const { DateTime } = require("luxon");

/**
 * Holds redirect types. Each key is the redirect type, and the value is an object with the name of the api call to retrieve the url for the redirect.
 * We also set url in the object to undefined for clarity - that will cache the url so we don't need to keep calling the api to get it.
 * Not used but leaving the infrastructure in place in case we need it again.
 */
let redirects = {

};

function callApi(path, data, o = {}) {
    const defaultOptions = { method: 'GET', redirectType: '' };
    o = Object.assign({}, defaultOptions, o);

    return getApiUrl(o.redirectType).then(url => {
        const fullPath = `${url}/${path}`;
        const headers = {
            'Content-Type': 'application/json' 
        };
        axios.defaults.withCredentials = true;

        let call = null;
        if (o.method === 'GET') {
            const queryString = fullPath + (data ? '?' + new URLSearchParams(data).toString() : '');
            const limit = 2000;
            if (queryString.length >= limit) {
                return Promise.reject("Query string too long in API request");
            }
            call = axios.get(queryString, { headers });
        } else {
            call = axios({ method: o.method, url: fullPath, data: JSON.stringify(data), headers });
        }

        return call.catch((e) => {
            if (e.response.status == 401) {
                router.push('/login');
            }
            throw e;
        });
    });
}

function getApiUrl(redirectType) {
    if (!redirectType) {
        return Promise.resolve(appConfig.backend_url);
    }

    if (!(redirectType in redirects)) {
        return Promise.reject(`invalid redirect URL type ${redirectType}`);
    }

    // previously set - just return it
    if (redirects[redirectType].url) {
        return Promise.resolve(redirects[redirectType].url);
    }

    // if this is the first time using this redirect URL, get and set it now
    // TODO this nested recursive call works for now but probably a bad idea to leave this way
    return callApi(redirects[redirectType].api)
        .then((response) => redirects[redirectType].url = response.data)
        .catch((err) => {
            console.error(`Error getting ${redirectType} redirect url`, err);
            return Promise.reject(err);
        });
}

export default {

    deleteAiPromptGroup: (ai_prompt_group_id) => callApi('results/prompt_group', { ai_prompt_group_id }, { method: 'DELETE' }),

    getAiEngineModels: () => callApi('results/engine_models'),

    getAiPromptGroupConfig: (ai_prompt_group_id, client_id) => callApi('results/prompt_group_config', { ai_prompt_group_id, client_id }),

    getAiPromptGroups: (client_id) => callApi('results/prompt_groups', {client_id}),

    getPromptPollStatus: (ai_prompt_group_ids) => callApi('results/prompt_poll_status', { ai_prompt_group_ids }),

    getAiPromptTemplates: () => callApi('results/prompt_templates'),

    getUserInfo: () => callApi('user/user_info'),

    login: (username, password) => callApi('user/token', { username, password }, { method: 'POST' }),

    tokenlogin: (token) => callApi('tokenLogin', { token }),

    logout: () => callApi('user/logout', {}, { method: 'POST' }),

    resetPassword: (code, password) => callApi('resetPassword', { code, password }),

    sendPasswordResetEmail: (email) => callApi('email/password-reset-email', { email }, { method: 'POST' }),

   // upsertAiPromptGroup: (ai_prompt_group, client_id) => callApi('results/upsert_ai_prompt_group', { ai_prompt_group, client_id }, { method: 'POST' }),

    upsertAiPromptGroup: (ai_prompt_group, client_id) => {
        // Create the payload with the nested structure
        const payload = {
            ...ai_prompt_group,
            client_id: client_id
        };
        return callApi('results/upsert_ai_prompt_group', payload, { method: 'POST' });
    },
    
    mongoGetResponsesByPromptGroup: (ai_prompt_group_id, dateRange, client_id) => callApi('results/responses_by_prompt_group', { ai_prompt_group_id, start_date: formatDate(dateRange[0]), end_date: formatDate(dateRange[1]), client_id }),

    mongoGetResponsesByPromptGroupForDate: (ai_prompt_group_id, date, client_id) => callApi('results/responses_by_prompt_group', { ai_prompt_group_id, start_date: formatDate(date), client_id }),

    getPromptGroupsForClient: (client_id) => callApi('results/prompt_groups_for_client', {client_id}),

    getAccountMax: (client_id) => callApi('results/account_max', {client_id}),

    getResponsesWithTags: (client_id, ai_prompt_group_id, dateRange, ai_prompt_template_tag_id) => callApi('results/responses_with_tags', { client_id, ai_prompt_group_id, start_date: formatDate(dateRange[0]), end_date: formatDate(dateRange[1]), ai_prompt_template_tag_id }),
    
    pausePromptGroup: (ai_prompt_group_id, client_id) => callApi('results/pause_prompt_group', { ai_prompt_group_id, client_id}, { method: 'POST' }),
    
    unPausePromptGroup: (ai_prompt_group_id, client_id) => callApi('results/unpause_prompt_group', { ai_prompt_group_id, client_id}, { method: 'POST' }),

    generateAIReport : ( clientid, aiPromptGroupId, entityName, displayName, aiEngineModelId, modelType, modelDisplayType, reportStartDate, reportEndDate, aiReportLookupType) => callApi('reports/generate_ai_report', { clientid, aiPromptGroupId, entityName, displayName, aiEngineModelId, modelType, modelDisplayType, reportStartDate, reportEndDate, aiReportLookupType} ),
    
    getAIReportStatus : ( clientid) => callApi('reports/ai_report_status', { clientid} ),
    
    deleteAIReport: (ai_client_report_id) => callApi('reports/ai_track_over_time', { ai_client_report_id }, { method: 'DELETE' }),

    getS3BucketFile: (reportPath) => callApi('cloud/file_content', { reportPath }),
}

function formatDate(date) {
    return DateTime.fromJSDate(date).toFormat('yyyy-MM-dd');
}