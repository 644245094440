<template>
  <div>
    <div class="form-group" v-if="!editMode">
      <div class="prompts-header form-field">
        <div class="icon-title">
          <label>Prompt Suggestions</label>
        </div>
        <span class="description">What are we asking AI?</span>
        <div class="btn-group mt-2" data-toggle="buttons">
          <div v-for="(row, rowIndex) in groupedTemplateGroups" :key="rowIndex" class="btn-row">
            <div v-for="(aiTemplateGroup, colIndex) in row" :key="colIndex" class="btn-col">
              <input type="radio" class="btn-check" autocomplete="off" />
              <label class="btn btn-outline-primary" :class="{ active: isActive(aiTemplateGroup) }"
                @click="updateAiTemplateGroupSelection(aiTemplateGroup)">
                {{ aiTemplateGroup.ai_prompt_template_group }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="add-custom">
      <div class="form-field">
        <label>
          Edit prompt
          <i class="bi bi-info-circle add-prompt-info" :class="{ 'show': showHelpInfo }" @click="showHelpInfo = true"
            v-click-outside="hideHelpInfo"></i>
        </label>
        <input type="text" class="form-control mt-1" v-model="aiPrompt" :placeholder="aiPrompt ? '' : 'Enter a custom prompt'" />
        <div class="error-message" :class="{ 'visible': validationErrors.prompts }">Prompts are required</div>
      </div>
      <!-- <form-button class="btn-sm" @click="acceptEditedPrompt()" :disabled="!aiPrompt.trim() || !canAddPrompts">
        Accept
      </form-button> -->
    </div>
    <div class="prompt-set">
      <div class="ai-prompt editing-prompt">
        <label v-html="promptDisplay(aiPrompt)" />
      </div>

      <!-- <div v-for="(aiPrompt, index) in aiPrompts" :key="index" class="ai-prompt"
        :class="{ 'editing-prompt': this.editingPromptIndex == index }">
        <label :for="'prompt-' + index" v-html="promptDisplay(aiPrompt)" />
        <div @click="toggleEditPrompt(index)" class="icons"
          :class="this.editingPromptIndex == index ? 'bi bi-x-circle' : 'pencil'"></div>
      </div> -->
    </div>
    <span class="description" v-if="!canAddPrompts">Limited to {{ maxPrompts }} prompts. To add a new one, first
      remove a prompt from the list.</span>
  </div>
</template>

<script>
//import FormButton from "@/components/form-elements/FormButton.vue";
import vClickOutside from "click-outside-vue3";

export default {
  emits: [
    "addAiPrompt",
    "removeAiPrompt",
    "setAiPrompt",
    "updateAiTemplateGroupSelection",
    "toggleEditPrompt",
    "acceptEditedPrompt",
  ],
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    aiPromptProp: String,
    aiPrompts: Array,
    mode: String,
    templateGroups: Array,
    aiPromptTemplateGroupSelection: Object,
    selectedAiPromptGroup: String,
    editingPromptIndex: Number,
    validationErrors: Object, 
  },
  data() {
    return {
      showHelpInfo: false,
    };
  },
  components: {
    // FormButton,
  },
  computed: {
    editMode() {
      return this.mode === 'prompts';
    },
    maxPrompts: {
      get() {
        return this.$store.state.ai.maxPrompts;
      },
    },
    aiPrompt: {
      get() {
        return this.aiPromptProp;
      },
      set(value) {
        this.$emit("setAiPrompt", value);
      },
    },
    isEditingPrompt() {
      return this.editingPromptIndex >= 0;
    },
    canAddPrompts() {
      return this.aiPrompts?.length < this.maxPrompts || this.isEditingPrompt;
    },
    groupedTemplateGroups() {
      const groups = {};
      this.templateGroups
        .filter(group => group.ui_visible == 1)
        .forEach(group => {
          if (!groups[group.sort_row]) {
            groups[group.sort_row] = [];
          }
          groups[group.sort_row].push(group);
        });

      for (const row in groups) {
        groups[row].sort((a, b) => a.sort_column - b.sort_column);
      }

      return groups;
    }
  },
  methods: {
    hideHelpInfo() {
      this.showHelpInfo = false;
    },
    updateAiTemplateGroupSelection(aiTemplateGroup) {
      this.$emit("updateAiTemplateGroupSelection", aiTemplateGroup);
    },
    addAiPrompt() {
      this.$emit("addAiPrompt");
    },
    acceptEditedPrompt() {
      this.$emit("acceptEditedPrompt");
    },
    removeAiPrompt(index) {
      this.$emit("removeAiPrompt", index);
    },
    toggleEditPrompt(index) {
      this.$emit("toggleEditPrompt", index);
    },
    isActive(aiTemplateGroup) {
      return this.aiPromptTemplateGroupSelection.ai_prompt_template_group_id === aiTemplateGroup.ai_prompt_template_group_id;
    },
    promptDisplay(prompt) {
      // Replace occurrences of ## with the selected entity name wrapped in <entity-name> tags
      if (this.selectedAiPromptGroup) {
        const display = `<entity-name>${this.selectedAiPromptGroup}</entity-name>`;
        prompt = prompt.replace(/##/g, display);
      }
      return prompt;
    }

  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_mixins.scss";
@import "@/scss/dialog-prompt-group-model.scss";

.add-prompt-info {
  position: relative;
  cursor: pointer;

  &.show:after {
    content: 'Type ## to include the entity name inside the prompt. When the prompt is used for peers of this entity, the entity name will be replaced with the name of the peer.';
    display: block;
    position: absolute;
    width: 23rem;
    top: 1rem;
    left: 1rem;
    border: solid 1px black;
    box-shadow: 0px 0px 15px var(--popup-shadow-color);
    padding: 2px;
    color: white;
    background-color: black;
    font-style: normal;
    z-index: calc(var(--z-popup-overlay) + var(--z-raise-more));
  }
}

.prompts-header {
  display: flex;

  .btn-group {
    gap: .5rem;
    flex-wrap: wrap;

    label.btn {
      max-width: 11rem;
      padding-left: 1rem;
      padding-right: 1rem;
      height: 36px;
      border-radius: 40px;
      @include body3;

      &:hover,
      &.active {
        background-color: var(--primary-blue5);
        color: var(--white);
      }

      &.active {
        box-shadow: 1px 1px 3px var(--grey9);
        border-color: white;
      }
    }
  }
}

.prompts {
  display: flex;
  flex-direction: column;

  .add-custom {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: .5rem;
    margin-top: 1rem;

    .form-field {
      flex-grow: 1;
    }
  }
}

.prompt-set {
  margin-top: 1rem;
  margin-bottom: 1rem;

  .icons {
    cursor: pointer;
    transition: filter .3s ease;
    height: 18px;
    width: 18px;

    &:before {
      vertical-align: text-top;
    }

    &:not(:hover) {
      filter: var(--icon-fade-filter);
    }
  }
}

.ai-prompt {
  display: flex;
  padding: 0.5rem;
  align-items: center;
  gap: 1rem;
  align-self: stretch;

  &:not(:last-child) {
    border-bottom: 1px solid var(--grey4);
  }

  label {
    flex: 1 0 0;
    color: var(--grey8);
    @include body3;
  }

  &.editing-prompt label {
    color: var(--grey7);
    font-style: italic;
  }
}

.btn-row {
  display: flex;
  flex-wrap: wrap;
  gap: .3rem;
  margin-bottom: 0;
}

.error-message {
  color: var(--red2);
  @include body3;
  font-size: .70rem;
  visibility: hidden; 
  
  &.visible {
    visibility: visible;
  }
}

</style>

<style lang="scss">
entity-name {
  border: solid 1px var(--primary-blue4);
  border-top-color: var(--primary-blue2);
  border-left-color: var(--primary-blue2);
  background: var(--primary-blue2);
  padding: 0 2px 1px;
  color: var(--primary-blue7);
}
</style>
