<template>
  <div class="dropdown d-flex flex-column" ref="dropdownContainer">
    <span v-if="showTitle" class="title">{{ dropdownName }}</span>
    <div class="dropdown-toggle" @click="toggleDropdown">
      <input
        type="text"
        v-model="searchQuery"
        @input="debouncedSearch"
        @focus="openDropdown"
        @click.stop
        :placeholder="placeholder"
        class="dropdown-input"
        :class="{ 'selected-value': selectedValue }"
      />
      <div class="icons dropdown-arrow"></div>
    </div>
    <div class="dropdown-menu" v-show="isOpen" >
      <slot name="dropdown-content" :items="filteredItems"></slot>
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash';

export default {
  props: ["showTitle", "dropdownName", "placeholder", "selectedValue", "dropdownButton", "dropdownDisplay", "isTagDrilldown", "showIcon", "displayValue", "items", "originalSelection"],
  data() {
    return {
      searchQuery: '',
      isOpen: false,
    };
  },
  created() {
    // Add click event listener to handle clicks outside the dropdown
    document.addEventListener('click', this.handleOutsideClick);
  },
  unmounted() {
    // Remove event listener when component is destroyed
    document.removeEventListener('click', this.handleOutsideClick);
  },
  methods: {
    debouncedSearch: debounce(function () {
      this.$emit('search', this.searchQuery);
    }, 300),
    openDropdown() {
      this.isOpen = true;
      this.searchQuery = ''; // Reset the search query to show the entire list
    },
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
    handleOutsideClick(event) {
      const container = this.$refs.dropdownContainer;
      // Check if click is outside the dropdown and not on a peer arrow
      if (container && 
          !container.contains(event.target) && 
          !event.target.closest('.collapse-peers')) {
        this.isOpen = false;
        this.searchQuery = this.originalSelection;
      }
    },
  },
  computed: {
    filteredItems() {
      if (this.searchQuery) {
        return this.items.filter(item => 
          item.display_name.toLowerCase().includes(this.searchQuery.toLowerCase())
        );
      }
      return this.items;
    },
  },
  watch: {
    selectedValue(newVal) {
      this.searchQuery = newVal;
    },
    displayValue(newVal) {
      this.searchQuery = newVal;
    },
    originalSelection(newVal) {
      this.searchQuery = newVal;
      this.isOpen = false; 
    },    
  },
  mounted() {
    this.searchQuery = this.selectedValue || '';
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_mixins.scss";
@import "@/scss/icons.scss";

.dropdown {
  position: relative;
}

.title {
  @include title-styles;
  margin-left: 3px;
  color: var(--Primary, #545f71);
  font-size: 12px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: -0.24px;
}

.dropdown-toggle {
  display: flex;
  min-width: 15rem;
  width: -moz-fit-content;
  width: fit-content;
  height: 3.75rem;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  align-self: stretch;
  border-radius: 2.5rem;
  border: 1.5px solid var(--blue3, #d3deee);
  background: var(--grey1, #f6f6f6);
}

.dropdown-input {
  border: none;
  outline: none;
  flex: 1;
  padding: 0.5rem;
  color: var(--grey9, #17191c);
  margin-left: .8rem;
  font-family: 'Open Sans';
  font-size: 2rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 30rem;
  background: transparent;
}

.dropdown-arrow {
  display: flex;
  width: 2.5rem !important;
  height: 1.5rem !important;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  margin: .15rem 0;
}

/* Remove the ::after content that draws the down arrow icon */
.dropdown-toggle::after {
  content: none;
  /* Remove the icon */
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1.5px solid var(--blue3, #d3deee); /* Match the input border */
  border-radius: .5rem; 
  max-height: 600px;
  overflow-y: auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  white-space: normal;
  word-wrap: break-word;
  width: 30rem;
}

.dropdown-item {
  padding: 0.5rem 0.75rem;
  cursor: pointer;
  white-space: normal;
  word-wrap: break-word;
  list-style-type: none;
  border-bottom: 1px solid var(--grey3, #E9EAED); /* Add a bottom border to items */

  &:not(.greyout) {
    cursor: pointer;
  }

  &.greyout {
    color: var(--grey5);
    cursor: default;
  }

  &:hover {
    background-color: var(--blue3, #d3deee); /* Match the input border color on hover */
    color: var(--grey9, #17191c); /* Match the input text color on hover */
  }

  &:last-child {
    border-bottom: none; /* Remove the bottom border for the last item */
  }
}
</style>
