<template>
  <view-wrapper>
    <div class="entity-analysis">
      <!-- upper section -->
      <div class="upper-section">
        <div class="d-flex flex-column">
          <div class="dropdown-container prompt-group-dropdown">
            <div class="dropdown d-flex align-items-center">
              <span class="button-text">What does AI think about</span>
              <client-dropdown :shouldShowPeers="true" />
            </div>
          </div>
        </div>
      </div>

      <!-- lower section -->
      <div class="lower-section">
        <div class="response-view">
          <div class="response-top-section">
            <div class="engine-option-buttons">
              <button v-for="button in filteredAiEngineModels" :key="button.ai_engine_model_id" class="btn p-2 m-1"
                :class="{ selected: selectedAiEngineModel?.model_type === button.model_type }"
                :disabled="!promptsForEngine(button)" @click="selectAiEngineModel(button)">
                <div class="d-flex ms-1 me-1">
                  <div class="icon-wrapper">
                    <span :class="'icons ' + button.model_type"></span>
                  </div>
                  <span class="ms-1 m-auto">{{ getModelName(button.model_type) }}</span>
                </div>
              </button>
            </div>
            <date-selector :show-label="false" />
          </div>

          <div class="scroll-container" v-if="!hasData">
            There is no current data available
          </div>
          <div v-else class="scroll-container">
            <div v-for="(prompt, index) in filteredPromptsByEngine" :key="prompt.content"
              class="response-container flex-column">
              <div class="prompt-name pt-3 pb-2">
                <span>{{ prompt?.ai_prompt }}</span>
              </div>
              <div class="fa-sm pt-2 pb-2 mb-4">
                <div class="d-flex flex-column">
                  <Tags :response="prompt" :routeback="route.entityAnalysis" class="mb-1" @tagHover="handleTagHover"
                    :shouldHighlightOnHover="true" />
                  <span :ref="'prompt_' + index" v-html="generateHighlightedContent(prompt)"></span>
                </div>
              </div>
              <sources v-if="prompt?.ai_prompt_id" :prompts="[prompt]" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </view-wrapper>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ROUTE, AiModelDisplayNames } from "@/constants";
import ViewWrapper from "@/views/ViewWrapper.vue";
import Tags from "@/components/form-elements/tags/index.vue";
import ClientDropdown from "@/components/form-elements/ClientDropdown.vue";
import DateSelector from "@/components/form-elements/DateSelector.vue";
import Sources from "@/components/form-elements/Sources.vue";
import aiUtil from "@/utils/aiUtil.js";

export default {
  components: {
    ViewWrapper,
    Tags,
    ClientDropdown,
    DateSelector,
    Sources
  },

  data() {
    return {
      route: ROUTE,
      summaries: {
        list: [],
        byDate: {},
      },
      chartDataForTag: null,
      prompts: [],
      preSelectedTime: [null, null],
      highlightTag: null,
      initialLoadComplete: false, // Flag to track initial load
    };
  },
  methods: {
    ...mapActions("general", ["setView", "setShowProgress"]),
    ...mapActions("ai", [
      "setAiTag",
      "setAiEngineModel",
      "setAiPromptGroup",
      "initializeAI",
      "setAppropriatePromptGroup",
    ]),
    handleTagHover(tag) {
      this.highlightTag = tag;
    },
    highlightTagInContent(content, tag) {
      if (!content || !tag) return content;

      return content.replace(
        new RegExp(tag?.phrase, "gi"),
        `<span class="highlighted-tag">$&</span>`
      );
    },
    generateHighlightedContent(prompt) {
      if (!prompt || !prompt.content) return '';

      let content = prompt.content;
      const sentiments = prompt.sentiments;

      if (this.highlightTag) {
        content = this.highlightTagInContent(content, this.highlightTag);
      }

      if (!sentiments) return content;

      sentiments.forEach(phrase => {
        if (phrase && phrase.phrase) {
          content = content.replace(
            new RegExp(phrase.phrase, "gi"),
            `<span class="${aiUtil.getSentiment(phrase.score, this.neutralThreshold)}-text">$&</span>`
          );
        }
      });
      return content;
    },

    getModelName(modelType) {
      return AiModelDisplayNames[modelType];
    },

    changePreSelectedTime(value) {
      this.preSelectedTime = value;
    },

    async handleTagButtonClick(tag) {
      await this.setAiTag(tag);
      this.$router.push({ path: this.route.tagDrilldown + this.route.entityAnalysis });
    },

    setDefaultAiEngineModel(prompts) {
      if (this.aiEngineModels.length > 0) {
        if (!prompts || prompts.length === 0) {
          let matchedRecord = this.aiEngineModels.find(
            (model) => model.model_type === 'chatgpt'
          );

          if (!matchedRecord && this.aiEngineModels.length > 0) {
            matchedRecord = this.aiEngineModels[0];
          }

          if (matchedRecord) {
            this.setAiEngineModel(matchedRecord);
          }
          return;
        }

        const uniqueModelTypesSet = new Set(prompts.map((prompt) => prompt.model_type));
        const uniqueModelTypesArray = Array.from(uniqueModelTypesSet);

        const chatgptIndex = uniqueModelTypesArray.indexOf("chatgpt");
        const firstModelType = chatgptIndex !== -1 ? "chatgpt" : uniqueModelTypesArray[0];

        const matchedRecord = this.aiEngineModels.find(
          (model) => model.model_type === firstModelType
        );

        this.setAiEngineModel(matchedRecord);
      }
    },

    fetchPrompts() {
      if (!this.aiPromptGroup?.id) {
        return Promise.resolve();
      }

      this.setShowProgress(true);

      const client_id = this.userInfo?.client?.client_id;

      if (client_id) {
        return this.$api
          .mongoGetResponsesByPromptGroup(this.aiPromptGroup.id, this.preSelectedTime, client_id)
          .then((result) => {
            if (result && result.data) {
              this.prompts = result.data;
            } else {
              console.warn("No prompts received");
              this.prompts = [];
            }
            return result;
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
            throw error; // Re-throw the error to be handled by the caller if needed
          })
          .finally(() => {
            this.setShowProgress(false);
          });
      } else {
        console.error("Client ID is not available");
        this.setShowProgress(false);
        return Promise.reject("Client ID is not available");
      }
    },



    selectAiEngineModel(selectedAiEngineModel) {
      this.selectedAiEngineModel = selectedAiEngineModel;
    },

    async fetchData() {
      try {
        this.fetchPrompts().then(() => {
          this.setDefaultAiEngineModel(this.prompts);
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },

    promptsForEngine(engineModel) {
      if (this.prompts.length === 0) {
        return false;
      }

      return this.prompts.some(prompt => prompt.model_type === engineModel.model_type && prompt.content.trim() !== '');
    }
  },
  created() {
    this.preSelectedTime = [this.selectedDate, this.selectedDate];
  },
  watch: {
    aiPromptGroup() {
      if (this.initialLoadComplete) {
        this.fetchData();
      }
    },
    selectedDate(currentValue) {
      this.preSelectedTime = [currentValue, currentValue];

      this.fetchPrompts().then(() => {
        if (!this.aiEngineModel) {
          this.setDefaultAiEngineModel(this.prompts);
        }
      });
    },

    aiPromptGroups(currentValue) {
      if (currentValue?.length) {
        if (!this.aiPromptGroup?.id) {
          this.setAiPromptGroup(currentValue[0]);
        }
      }
    },
  },
  computed: {
    ...mapGetters("ai", {
      aiPromptGroup: "aiPromptGroup",
      aiPromptGroups: "aiPromptGroups",
      aiEngineModel: "aiEngineModel",
      aiEngineModels: "aiEngineModels",
      selectedDate: "selectedDate",
      neutralThreshold: "neutralThreshold",
      userInfo: "userInfo",
    }),

    hasData() {
      return this.uniqueModelTypes.includes(this.selectedAiEngineModel?.model_type);
    },
    promptGroupsWithPeers() {
      return this.aiPromptGroups;
    },
    promptsByEngine() {
      return this.prompts.filter((prompt) => prompt.model_type === this.selectedAiEngineModel.model_type);
    },
    filteredPromptsByEngine() {
      return this.promptsByEngine.filter(prompt => prompt.content !== '');
    },
    filteredAiEngineModels() {
      const models = this.aiEngineModels.filter((button) => button.model_type !== "all");
      const modelArr = Object.keys(AiModelDisplayNames);
      return models.sort((a, b) => modelArr.indexOf(a.model_type) - modelArr.indexOf(b.model_type));
    },
    selectedAiEngineModel: {
      get() {
        return this.$store.state.ai.aiEngineModel;
      },
      set(value) {
        this.setAiEngineModel(value);
      },
    },
    uniqueModelTypes() {
      const uniqueModelTypesSet = new Set(this.prompts.map((item) => item.model_type));
      return [...uniqueModelTypesSet];
    },
  },
  async mounted() {
    this.setView("entityAnalysis");
    await this.initializeAI({ notifier: this.$notifier });

    this.fetchData();
    this.initialLoadComplete = true; // Set the flag to true after initial fetch
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_icons.scss";
@import "@/scss/_mixins.scss";

.entity-analysis {
  width: 62rem;

  .upper-section {
    display: flex;
    margin: var(--component-spacing-v) 0 var(--component-spacing-v) var(--gutter-width);
    align-items: center;
    gap: 1.25rem;

    .prompt-group-dropdown {
      .dropdown {
        display: flex;
        justify-content: space-between;
        gap: 1rem;
        @include title-styles;
        font-size: 32px;
      }

      .button-text {
        color: var(--grey8);
        @include h1-style;
      }

      .dropdown-menu {
        border-radius: 20px;
      }
    }
  }

  .lower-section {
    display: flex;
    align-items: flex-start;

    .response-top-section {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    .scroll-container {
      position: relative;

      .select-icon {
        position: absolute;
        right: 8px;
        cursor: pointer;
      }

      .prompt-name {
        font-weight: 600;
      }

      .frequency {
        display: flex;
        padding: 8px 0px;
        align-items: center;
        gap: 8px;
        color: var(--primary-blue5);
        text-decoration-line: underline;
        cursor: pointer;
      }
    }

    .engine-option-buttons {
      display: flex;

      .btn {
        display: flex;
        padding: 6px 15px 6px 6px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 40px;
        background: var(--grey1);

        @include body3;
        font-weight: 500;

        min-width: 60px;

        &:disabled {
          background-color: #ccc;
          border-color: #ccc;
          color: white;
        }

        .icon-wrapper {
          background-color: white;
          border-radius: 10px;
          width: 20px;
          height: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .btn:hover,
      .btn.selected {
        background-color: var(--primary-blue5);
        color: var(--white);
        @include body3;
        font-weight: 500;
      }
    }

    :deep(.date-range) {
      width: 140px;
    }

    .response-view {
      display: flex;
      padding: var(--component-spacing-h-generous);
      flex-direction: column;
      align-items: flex-start;
      gap: 1.5rem;
      width: 100%;
      border-radius: 1.25rem;
      border: 1px solid var(--grey2);
      background: var(--white);

      .response-container {
        display: flex;
        width: 100%;
        padding: 0px 16px;
        justify-content: space-between;
        border-radius: 10px;
        border: 1px solid var(--grey3);
        background: var(--white);
        margin-bottom: 5px;

        :deep(.negative-text) {
          background-color: #ffa5a5;
        }

        :deep(.highlighted-tag) {
          background-color: yellow !important;
          transition: background-color 0.3s ease;
        }
      }

      .sources-title {
        color: var(--grey7);
        margin: 1rem 0;
        @include h4-style;
      }
    }
  }
}
</style>
