<template>
    <dropdown-search
      :selectedValue="searchQuery"
      :dropdownButton="clientDropdownButton"
      :dropdownDisplay="clientDropdownDisplay"
      :showTitle="showTitle"
      :dropdownName="dropdownName"
      :isTagDrilldown="isTagDrilldown"
      :placeholder="placeholder"
      :items="aiPromptGroups"
      :original-selection="aiPromptGroup?.display_name || ''"
      @search="handleSearch"
      @select="handleSelect"
    >
      <template v-slot:dropdown-content="{ items }">
        <ul class="dropdown-menu-list">
          <li
            v-for="(option, index) in items"
            :key="option.id"
            :class="[
              'dropdown-item',
              { 'greyout': greyOutNoPeers && !option.peers }
            ]"
            @click="handleSelect(option)"
          >
            <div>
              <div class="myrow">
                <div class="prompt-group-name">{{ option.display_name }}</div>
                <div
                  v-if="option.peers"
                  class="icons collapse-peers"
                  :class="{
                    'arrow arrow-down original-size': shouldShowPeers && showPeers(index),
                    'arrow arrow-right original-size': shouldShowPeers && !showPeers(index)
                  }"
                  @click.stop="toggleShowPeers(index)"
                ></div>
              </div>
            </div>
            <div>
              <ul class="peers-section" v-if="showPeers(index)">
                <li
                  v-for="(peer, i) in option.peers"
                  :key="i"
                  @click.stop="setPeerAsPromptGroup(peer)"
                >
                  {{ peer.ai_prompt_group }} (peer)
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </template>
    </dropdown-search>
  </template>
  
  <script>
  import DropdownSearch from "@/components/form-elements/DropdownSearch.vue";
  import { mapGetters, mapActions } from "vuex";
  import Fuse from 'fuse.js';
  
  export default {
    props: ["showTitle", "isTagDrilldown", "shouldShowPeers", "greyOutNoPeers", "placeholder"],
    components: {
      DropdownSearch,
    },
    data() {
      return {
        dropdownName: "Entity",
        clientDropdownButton: "client-dropdown-button",
        clientDropdownDisplay: "client-dropdown-display",
        showPeersArr: [],
        searchQuery: '',
        fuse: null,
      };
    },
    methods: {
      ...mapActions("ai", [
        "setAiPromptGroup",
      ]),
      setPeerAsPromptGroup(peer) {
        let promptGroup = { id: peer.ai_peer_prompt_group_id, name: peer.ai_prompt_group, display_name: peer.ai_prompt_group, parent_prompt_group_id: peer.ai_parent_prompt_group_id };
        this.setAiPromptGroup(promptGroup);
      },
      handleSelect(option) {
        this.setAiPromptGroup(option);
        this.$emit('select', option);
      },
      toggleShowPeers(i) {
        if (!this.showPeersArr[i]) {
          this.showPeersArr[i] = true;
        } else {
          this.showPeersArr[i] = false;
        }
      },
      showPeers(i) {
        if (this.showPeersArr[i] == undefined) {
          return false;
        }
        return this.showPeersArr[i];
      },
      initializeFuse() {
        const options = {
          keys: ['display_name'],
          threshold: 0.3,
        };
        this.fuse = new Fuse(this.aiPromptGroups, options);
      },
      handleSearch(query) {
        this.searchQuery = query;
      },
    },
    computed: {
      ...mapGetters("ai", {
        aiPromptGroup: "aiPromptGroup",
        aiPromptGroups: "aiPromptGroups",
      }),
    },
    watch: {
      aiPromptGroups: {
        immediate: true,
        handler() {
          this.initializeFuse();
        },
      },
      aiPromptGroup(newVal) {
        this.searchQuery = newVal?.display_name || '';
      },
    },
    mounted() {
      // Ensure searchQuery is set correctly when the component is mounted
      this.searchQuery = this.aiPromptGroup?.display_name || '';
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .dropdown-menu-list {
    max-height: 600px; /* Double the max height */
    overflow-y: auto; /* Enable vertical scroll */
    padding: 0;
    margin: 0;
    list-style: none; /* Remove default list styling */
  
    .dropdown-item {
      padding: 0.5rem 0.75rem;
      cursor: pointer;
      white-space: normal; /* Ensure text wraps to the next line */
      word-wrap: break-word; /* Break words if they are too long */
      list-style-type: none; /* Remove bullet points */
  
      &:not(.greyout) {
        cursor: pointer;
      }
  
      &.greyout {
        color: var(--grey5);
        cursor: default;
      }
  
      &:hover {
        background-color: var(--grey2);
      }
    }
  }
  
  .dropdown-menu.show {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.25rem;
    border-radius: 0.75rem;
    border: 1px solid var(--grey3, #E9EAED);
    background: var(--white, #FFF);
    margin: 0;
    padding: 0;
    width: 30rem; /* Fixed width */
  }
  
  .dropdown-menu.show li {
    display: flex;
    flex-direction: column;
    padding: 0.50rem 0.75rem;
    align-self: stretch;
    min-width: auto;
  
    div {
      font-family: 'Inter';
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
  
      &.icons {
        display: flex;
  
        .collapse-peers {
          cursor: pointer;
  
          &.arrow {
            margin-left: 1rem;
          }
  
          &.arrow-down {
            margin-top: 1rem;
          }
  
          &:hover:after {
            border-color: var(--icon-blue);
          }
        }
      }
    }
  }
  
  .dropdown-menu.show li:first-child {
    border-radius: 0.75rem 0.75rem 0 0;
  }
  
  .dropdown-menu.show li:last-child {
    border-radius: 0 0 .75rem .75rem;
  }

  .myrow {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
  }
  
  .column {
    display: flex;
    flex-direction: column;
  }
  
  .prompt-group-name {
    font-weight: normal; /* Revert to normal font weight */
  }
  
  .peers-section {
    padding-left: 1.2rem;

    list-style-type: none; /* Remove bullet points */
    li {
        padding-top: .5rem;
    }
  }
  </style>
  