import { createApp } from 'vue';
import App from './App.vue';

import appConfig from '@/../config/config.json';

import store from "@/store";
import router from '@/router';
import '@/config/axios'; 

import VueToast from 'vue-toast-notification';
import PrimeVue from 'primevue/config';
import Tooltip from 'primevue/tooltip';
import api from '@/api';
import 'vue-toast-notification/dist/theme-sugar.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import "bootstrap";
import 'vue-diff/dist/index.css';
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';

// common css definitions
import "@/scss/_bootstrap.scss";
import "@/scss/_colors.scss";
import "@/scss/_sizes.scss";
import "@/scss/_fonts.scss";

import "@/scss/_images.scss";


import 'primevue/resources/themes/bootstrap4-light-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';

// TODO remove these from main.js

import storage from "@/utils/storageUtil";

const previousUrl = storage.get('previousUrl');

const { DateTime } = require("luxon");

const app = createApp(App);

app.use(VueToast);
app.config.globalProperties.$dateTime = DateTime;
app.config.globalProperties.$api = api;

app.use(PrimeVue);
app.use(store);

router.beforeEach((to, from, next) => {
    app.config.globalProperties.$toast.clear();
    next();
});

app.use(router);

app.directive('tooltip', Tooltip);

app.mixin({
    beforeCreate() {
        // Set the initial route to the previous URL
        if ((!router.currentRoute.value.path || router.currentRoute.value.path === '/') && previousUrl) {
            router.replace(previousUrl);
        }
    },
});

router.isReady().then(() => {
    app.mount('#app');
});


// set the title element
const titleElement = document.getElementById('pageTitle');
titleElement.innerText = 'Five Blocks AIQ';

function setToastNotification(o) {
    const duration = o.type == "error" ? 5000 : o.duration;
    if (o.show && !o?.on_dismiss) {
        app.config.globalProperties.$toast.open({
            message: o.message,
            duration,
            dismissible: duration > 2500,
            type: o.type || "info",
        });
    } else if (o.show && o?.on_dismiss) {
        app.config.globalProperties.$toast.open({
            message: o.message,
            dismissible: true,
            type: o.type || "info",
            duration: 0,

            onClick: o.click,
        });
    } else {
        app.config.globalProperties.$toast.clear();
    }
}

//TODO: move this notifier functionality out of main.js don't think it belongs here
app.config.globalProperties.$notifier = {
    error: ({ message, e }) => {
        setToastNotification({ show: true, type: 'error', message });
        console.error(message, e);
    },
    info: ({ message, duration = 2500 }) => {
        setToastNotification({ show: true, message, duration });
    },
    hide: () => {
        setToastNotification({ show: false, message: "" });
    },
    setNotification :setToastNotification
}

//if I'm in prod
if (appConfig.gClientId) {
    let headscript = document.createElement("script");
    headscript.innerHTML = "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-59SLHS8Q');"
    document.head.insertBefore(headscript, document.head.childNodes[0]);
    let noscript = document.createElement("noscript");
    noscript.innerHTML = '<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-59SLHS8Q"height="0" width="0" style="display:none;visibility:hidden"></iframe>';
    document.body.insertBefore(noscript, document.body.childNodes[0]);
}
