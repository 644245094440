<template>
  <div class="modal fade" id="openModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="openModalLabel" aria-hidden="true">
    <div class="modal-dialog" :class="{ 'modal-xl': mode == 'all', 'modal-lg': mode == 'prompts' }">
      <div class="modal-content">

        <div v-if="verifyingClose" class="verify-close">
          <div class="verify-overlay"></div>
          <div class="verify-prompt">
            Discard unsaved changes?
            <div class="button-container">
              <form-button bsType="secondary" @click="verifyingClose = false" class="btn-sm">Cancel</form-button>
              <form-button class="btn-sm" @click="toggleModal(false)">Discard</form-button>
            </div>
          </div>
        </div>

        <div class="modal-header">
          <h5 class="modal-title" id="openModalLabel">
            <span class="icons" :class="iconName" />
            {{ title }}
          </h5>

          <button type="button" class="btn-close" @click="checkForUnsavedChanges" aria-label="Close"></button>
        </div>

        <div class="modal-body">

          <div class="container body-container">
            <div class="row">
              <div v-if="!['prompts'].includes(mode)" :class="{
      'col-lg-5': ['all'].includes(mode),
      'col-md-12': ['all'].includes(mode),
      'col': !['all'].includes(mode),
    }">
                <general v-if="['all'].includes(mode)" :selected-ai-prompt-group-prop="selectedAiPromptGroup"
                  :display-name="displayName" :validation-errors="validationErrors"
                  @handle-prompt-group-change="handlePromptGroupChange"
                  @handle-display-name-change="handleDisplayNameChange" />

                <engines v-if="['all', 'engines'].includes(mode)" :edit-mode="mode === 'engines'"
                  :selected-ai-engine-models-prop="selectedAiEngineModels"
                  @set-selected-ai-engine-models="setSelectedAiEngineModels" />
                <div class="error-message" :class="{ 'visible': validationErrors.engines }">AI engine selections are
                  required</div>

                <peers class="col peers" v-if="['all', 'peers'].includes(mode)" :edit-mode="mode === 'peers'"
                  :ai-peer-prop="aiPeer" :ai-peers="aiPeers" :validation-errors="validationErrors"
                  @add-ai-peers="addAiPeers" @remove-ai-peer="removeAiPeer" @set-ai-peer="setAiPeer" />

              </div>

              <prompts class="col prompts" v-if="['all', 'prompts'].includes(mode)" :mode="mode"
                :ai-prompt-prop="aiPrompt" :ai-prompts="aiPrompts" :template-groups="templateGroups"
                :selected-ai-prompt-group="selectedAiPromptGroup"
                :ai-prompt-template-group-selection="aiPromptTemplateGroupSelection"
                :editing-prompt-index="editingPromptIndex" :validation-errors="validationErrors"
                @accept-edited-prompt="acceptEditedPrompt" @toggle-edit-prompt="toggleEditPrompt"
                @add-ai-prompt="addAiPrompt" @remove-ai-prompt="removeAiPrompt" @set-ai-prompt="setAiPrompt"
                @update-ai-template-group-selection="updateAiTemplateGroupSelection" />

            </div>
          </div>

          <edit-topic v-if="['topic'].includes(mode)" :display-name="displayName"
            @handle-display-name-change="handleDisplayNameChange" />

        </div>

        <div class="modal-footer">
          <form-button bsType="secondary" @click="checkForUnsavedChanges()" class="btn-sm">
            Close
          </form-button>
          <form-button class="btn-sm" @click="saveAiPromptGroup()">
            <span v-if="!inProgress">Save</span>
            <span v-else> <i class="fa fa-spinner fa-spin"></i> Saving</span>
          </form-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from "bootstrap";
import { mapGetters } from "vuex";
import { _ } from "vue-underscore";
import General from "@/components/modals/DialogPromptGroupModal/General.vue";
import EditTopic from "@/components/modals/DialogPromptGroupModal/EditTopic.vue";
import Engines from "@/components/modals/DialogPromptGroupModal/Engines.vue";
import Prompts from "@/components/modals/DialogPromptGroupModal/Prompts.vue";
import Peers from "@/components/modals/DialogPromptGroupModal/Peers.vue";
import FormButton from "@/components/form-elements/FormButton.vue";
import { maxPrompts, maxPeers } from "@/constants";

export default {
  name: "dialogPromptGroupModal",
  emits: ["clearMode", "upsertPromptGroup"],
  components: {
    General,
    EditTopic,
    Engines,
    Prompts,
    Peers,
    FormButton,
  },
  props: {
    mode: String,
    aiPromptGroup: Object,
  },
  data() {
    return {
      modal: "",
      aiPrompt: "",
      aiPromptSets: {}, // the local copies of the template groups so we don't lose changes switching between types
      aiPeer: "",
      aiPeers: [],
      aiPromptTemplateGroupSelection: {},
      selectedAiEngineModels: [],
      selectedAiPromptGroup: "",
      displayName: "",
      verifyingClose: false,
      initialDataState: "",
      editingPromptIndex: 0,

      // used for the UI fiction of a custom group and gets switched out before saving
      customTemplateGroup: {
        ai_prompt_template_group_id: -1,
        ai_prompt_template_group: "Custom"
      },
      validationErrors: {
        topic: false,
        entity: false,
        engines: false,
        peers: false,
        prompts: false,
      },
    };
  },

  computed: {
    ...mapGetters("general", {
      inProgress: "inProgress",
    }),
    ...mapGetters("ai", {
      aiPromptTemplates: "aiPromptTemplates",
      userInfo: "userInfo",
      aiEngineModels: "aiEngineModels",
    }),
    inputNotReadyMessage() {
      switch (this.mode) {
        case "topic":
          return "Topic name is required";
        case "engines":
          return "AI engine selections are required";
        case "prompts":
          return "Prompts are required";
        default:
          return "Topic name, entity, AI engine selections, and prompts are required";
      }
    },
    title() {
      return {
        prompts: 'Prompts',
        engines: 'Select AI Engines',
        peers: 'Peers',
        topic: this.displayName,
      }[this.mode];
    },
    iconName() {
      return {
        // all: 'bi bi-sliders',
        topic: 'prompt-group-general',
        prompts: 'prompt-group-prompts',
        engines: 'prompt-group-general',
        peers: 'prompt-group-peers',
      }[this.mode];
    },
    isInputReady() {
      return (
        this.displayName &&
        this.selectedAiPromptGroup &&
        this.selectedAiEngineModels.length &&
        this.aiPrompts?.length &&
        this.aiPrompt
      );
    },
    templates() {
      return this.aiPromptTemplates.filter((t) => t.ai_prompt !== "Custom");
    },
    templateGroups() {
      const groups = Array.from(
        new Set(this.templates.map((item) => item.ai_prompt_template_group_id))
      ).map((id) => {
        const item = this.templates.find((obj) => obj.ai_prompt_template_group_id === id);
        return {
          ai_prompt_template_group_id: item.ai_prompt_template_group_id,
          ai_prompt_template_group: item.ai_prompt_template_group,
          ui_visible: item.ui_visible,
          sort_row: item.sort_row,
          sort_column: item.sort_column
        };
      });

      return groups;
    },
    aiPrompts: {
      get() {
        const key = this.aiPromptTemplateGroupSelection?.ai_prompt_template_group_id;
        return this.aiPromptSets[key];
      },
      set(value) {
        const key = this.aiPromptTemplateGroupSelection?.ai_prompt_template_group_id;
        if (key) {
          this.aiPromptSets[key] = value;
        }
      },
    },
    typedPeers() {
      const peerExists = (peer) => this.aiPeers.filter((p) => p.ai_prompt_group === peer).length;

      const peerList = this.aiPeer
        .split(",")
        .map((peer) => peer.trim())
        .filter((peer) => peer && !peerExists(peer));

      return [...new Set(peerList)] || [];
    },
    peerLimitExceededBy() {
      const aiPeersLength = this.aiPeers.length;
      const typedPeersLength = this.typedPeers.length;
      const value = aiPeersLength + typedPeersLength - maxPeers;
      return value;
    },
    peerLimitExceededMessage() {
      const slotsAvailable = maxPeers - this.aiPeers.length;
      const nPeers = slotsAvailable == 1 ? 'peer' : slotsAvailable + ' peers';
      return "The number of peers entered will exceed the peer limit (" + maxPeers + "). Pressing Add above will add only the first " + nPeers + " entered.";
    }
  },

  methods: {

    toggleModal(isShow) {
      if (!this.modal) {
        return;
      }

      this.aiPrompt = "";
      this.aiPeer = "";
      this.editingPromptIndex = 0;

      // Reset validation errors when the modal is opened
      this.validationErrors = {
        topic: false,
        entity: false,
        engines: false,
        peers: false,
        prompts: false,
        peersMessage: "",
      };

      if (isShow) {
        this.modal.show();
      } else {
        this.modal.hide();
        this.verifyingClose = false;
        this.$emit("clearMode");
      }
    },

    setAiPrompt(aiPrompt) {
      this.aiPrompt = aiPrompt;
    },
    setAiPeer(aiPeer) {
      this.aiPeer = aiPeer;
    },
    removeAiPrompt(index) {
      this.aiPrompts.splice(index, 1);
      if (this.editingPromptIndex >= 0) {
        this.aiPrompt = "";
        this.editingPromptIndex = 0;
      }
    },
    removeAiPeer(index) {
      this.aiPeers.splice(index, 1);
    },
    updateAiTemplateGroupSelection(aiPromptTemplateGroup) {
      this.aiPromptTemplateGroupSelection = aiPromptTemplateGroup;
      this.editingPromptIndex = 0;
      this.aiPrompt = this.unfixCustomPrompt(this.aiPrompts[0].ai_prompt);
    },

    toggleEditPrompt(index) {
      this.aiPrompt = this.unfixCustomPrompt(this.aiPrompts[index]?.ai_prompt);
    },

    loadAiPrompts() {
      switch (this.mode) {
        case '': // not set yet
          break;

        case 'all': // add ai topic
          this.templates.forEach(template => {
            const id = template.ai_prompt_template_group_id;
            this.aiPromptSets[id] = _.where(this.templates, { ai_prompt_template_group_id: id });
          });

          this.aiPromptSets[this.customTemplateGroup.ai_prompt_template_group_id] = [];

          break;

        default: // edit
          this.aiPromptSets[this.aiPromptGroup.ai_prompt_template_group_id] = eval(this.aiPromptGroup.ai_prompts);
          break;

      }
    },

    fixCustomPrompt(prompt) {
      return prompt.trim().replace(/##/g, `<entity>`);
    },

    // fix this to include all 3 possible template groups
    unfixCustomPrompt(prompt) {
      return prompt.replace(/<[^>]+>/gi, '##');
    },

    acceptEditedPrompt() {
      if (this.editingPromptIndex < 0) {
        return;
      }

      if (this.addAiPrompt(true)) {
        this.aiPrompts.splice(this.editingPromptIndex, 1);
      }

      this.editingPromptIndex = 0;
    },

    addAiPrompt(editing) {
      if (!this.aiPromptTemplateGroupSelection?.ai_prompt_template_group_id) {
        return false;
      }
      if (this.aiPrompts?.length == maxPrompts && !editing) {
        return false;
      }

      const prompt = this.fixCustomPrompt(this.aiPrompt);
      if (!prompt) {
        return false;
      }

      const exists = this.aiPrompts.filter((p) => p.ai_prompt.toLowerCase() === prompt.toLowerCase()).length;
      if (exists) {
        return false;
      }

      const groupId = this.aiPromptTemplateGroupSelection.ai_prompt_template_group_id;
      const groupName = this.aiPromptTemplateGroupSelection.ai_prompt_template_group;

      const newPrompt = {
        ai_prompt_template_group_id: groupId,
        ai_prompt_template_group: groupName,
        ai_prompt: prompt,
      };

      this.aiPrompts.push(newPrompt);

      return true;
    },

    addAiPeers() {
      const availableSlots = maxPeers - this.aiPeers.length;
      const peers = this.typedPeers.slice(0, availableSlots);

      peers.forEach((peer) => {
        this.aiPeers.push({ ai_prompt_group: peer });
      });

      this.aiPeer = "";
    },

    checkForUnsavedChanges() {
      const hasChanges = this.initialDataState != this.getDataState();
      if (hasChanges) {
        this.verifyingClose = true;
      } else {
        this.toggleModal(false);
      }
    },

    // to check for unsaved changes
    getDataState() {
      const data = JSON.parse(JSON.stringify(this.getDataToSave()));
      data.ai_prompts = this.aiPromptSets;
      return JSON.stringify(data);
    },

    getDataToSave() {

      const data = {
        ai_prompt_group: this.selectedAiPromptGroup, // entity
        ai_prompt_group_display_name: this.displayName, // topic name
      };

      data.ai_prompt_group_id = this.aiPromptGroup?.ai_prompt_group_id
        ? this.aiPromptGroup.ai_prompt_group_id // update existing group
        : -1; // add new group

      data.ai_prompt_group_models = this.selectedAiEngineModels.map(
        (m) => m.ai_engine_model_id
      );

      data.ai_prompt_template_group_id = this.aiPromptTemplateGroupSelection.ai_prompt_template_group_id;
      // remove reactivity so we can modify this local copy. This is only an issue when trying to get the initial state - I'm not sure why
      data.ai_prompts = JSON.parse(JSON.stringify(this.aiPrompts));

      // undo the "custom" template fiction
      if (data.ai_prompt_template_group_id === this.customTemplateGroup.ai_prompt_template_group_id) {
        data.ai_prompt_template_group_id = 1;
        data.ai_prompts.forEach(prompt => {
          prompt.ai_prompt_template_group_id = 1;
          prompt.ai_prompt_template_group = "Company/Brand";
        });
      }

      // replace the typed entity name with tags. Hashes shortcut was taken care of when adding the prompt.
      // data.ai_prompts.forEach(prompt => {
      //   prompt.ai_prompt = prompt.ai_prompt.replace(new RegExp(String.raw`\b${this.selectedAiPromptGroup}\b`, "gi"), `<${this.aiPromptTemplateGroupSelection.tag}>`);
      // });

      if (this.aiPeers) {
        this.aiPeers.forEach(
          (peer) =>
          (peer.ai_prompt_group_display_name =
            peer.ai_prompt_group +
            " " +
            this.aiPromptTemplateGroupSelection.ai_prompt_template_group)
        );
        data.ai_peers = this.aiPeers;
      }

      return data;
    },

    saveAiPromptGroup() {

      this.validationErrors = {
        topic: !this.displayName,
        entity: !this.selectedAiPromptGroup,
        engines: this.selectedAiEngineModels.length === 0,
        peers: this.peerLimitExceededBy > 0,
        prompts: !this.aiPrompt,
        peersMessage: this.peerLimitExceededMessage,
      };


      const validationKeys = this.mode === 'all' ? ['topic', 'entity', 'engines', 'peers', 'prompts'] : [this.mode];
      if (validationKeys.some(key => this.validationErrors[key])) {
        return;
      }

      this.acceptEditedPrompt();
      this.addAiPrompt();
      this.addAiPeers();

      const toUpsert = this.getDataToSave();
      this.fillCustomPromptTemplateIds(toUpsert.ai_prompts);
      this.$emit("upsertPromptGroup", toUpsert);
    },
    handlePromptGroupChange(value) {
      this.setSelectedAiPromptGroup(this.normalizeInput(value));
    },
    handleDisplayNameChange(value) {
      this.displayName = this.normalizeInput(value);
    },
    setSelectedAiEngineModels(selectedAiEngineModels) {
      this.selectedAiEngineModels = selectedAiEngineModels;
    },
    setSelectedAiPromptGroup(selectedAiPromptGroup) {
      this.selectedAiPromptGroup = selectedAiPromptGroup;
    },
    normalizeInput(input) {
      return input.trim().replace(/\s+/g, ' ');
    },
    fillCustomPromptTemplateIds(prompts) {
      const customTemplateIdByGroup = [];
      this.aiPromptTemplates
        .filter((t) => t.ai_prompt === "Custom")
        .forEach(
          (template) =>
          (customTemplateIdByGroup[template.ai_prompt_template_group_id] =
            template.ai_prompt_template_id)
        );

      prompts.forEach(
        (prompt) =>
        (prompt.ai_prompt_template_id ||=
          customTemplateIdByGroup[prompt.ai_prompt_template_group_id])
      );
    },
  },

  watch: {
    mode(value) {
      this.toggleModal(value);
    },
    aiPrompts() {
      if (this.mode === 'prompts') {
        // force to handle one prompt for now
        this.toggleEditPrompt(0);
      }
    },
    aiPromptTemplates: {
      deep: true,
      handler() {
        // Find the record with sort_row = 1 and sort_column = 1
        const selectedTemplate = this.templates.find(template =>
          template.sort_row === 1 && template.sort_column === 1
        );

        // Check if a matching template was found
        if (selectedTemplate) {
          this.aiPromptTemplateGroupSelection = {
            ai_prompt_template_group_id: selectedTemplate.ai_prompt_template_group_id,
            ai_prompt_template_group: selectedTemplate.ai_prompt_template_group,
          };
        } else {
          console.log('No template found');
        }
      },
    },


  },

  mounted() {
    const modalEl = document.getElementById("openModal");
    this.modal = new Modal(modalEl);

    this.$watch(
      (vm) => vm.aiPromptGroup,
      (aiPromptGroup) => {
        this.aiPrompt = "";

        if ("ai_prompt_group_models" in aiPromptGroup) {
          const preSelectedModels = JSON.parse(aiPromptGroup.ai_prompt_group_models);
          this.selectedAiEngineModels = [];
          this.aiEngineModels.forEach((aiModel) => {
            const foundAiModel = _.findWhere(preSelectedModels, {
              ai_engine_model_id: aiModel.ai_engine_model_id,
            });
            if (foundAiModel) {
              this.selectedAiEngineModels.push(aiModel);
            }
          });
        } else {
          // pre select all models
          this.selectedAiEngineModels = [...this.aiEngineModels];
        }

        this.selectedAiPromptGroup = aiPromptGroup?.ai_prompt_group || "";
        this.displayName = aiPromptGroup?.display_name;

        this.aiPeers = [];
        if ("ai_peers" in aiPromptGroup) {
          const aiPeersArray = eval(aiPromptGroup.ai_peers);
          if (aiPeersArray.length > 0 && aiPeersArray[0].ai_peers_id !== null) {
            this.aiPeers = aiPeersArray;
          }
        }

        if ("ai_prompt_template_group_id" in aiPromptGroup) {
          this.aiPromptTemplateGroupSelection = _.findWhere(this.templateGroups, {
            ai_prompt_template_group_id: aiPromptGroup.ai_prompt_template_group_id,
          });
        } else {
          // Use the the first item
          this.aiPromptTemplateGroupSelection = this.templateGroups.find(templateGroup => templateGroup.sort_row === 1 && templateGroup.sort_column === 1);
        }

        this.loadAiPrompts();

        if (this.mode) {
          this.initialDataState = this.getDataState();
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_mixins.scss";

.body-container {
  margin-bottom: 2rem;

  &>.row {
    gap: 2rem;

    &>.col {
      display: flex;
      flex-direction: column;

    }
  }
}

.prompts {
  flex-grow: 1.2;
  padding: 0;
}

.modal-dialog {
  padding: 1rem;
  background: var(--white);

  &:not(.modal-fullscreen) {
    border: 2px solid var(--grey2);
    border-radius: 1.25rem;
    box-shadow: 0px 0px 25px var(--popup-shadow-color);
  }

  .modal-content {
    border: 0;

    .modal-header {
      border: 0;

      h5 {
        color: var(--grey9);
        @include h2-style;
      }
    }

    .modal-title {
      display: flex;
      align-items: center;
      gap: .5rem;
      align-self: stretch;
    }
  }

  .modal-body {
    padding: 0;
  }

  .info-message {
    color: var(--grey7);
    @include body3;
  }

  .error-message {
    color: var(--red2);
    @include body3;
    font-size: .70rem;
    visibility: hidden;

    &.visible {
      visibility: visible;
    }
  }

  .verify-close {
    position: absolute;
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    .verify-overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: var(--z-popup-overlay);

      background-color: var(--bs-gray-600);
      opacity: .8;
    }

    .verify-prompt {
      background-color: white;
      z-index: calc(var(--z-popup-overlay) + var(--z-raise));
      padding: 1rem;
      border-radius: 1rem;

      .button-container {
        margin-top: 1rem;
        display: flex;
        justify-content: center;
        gap: .5rem;
      }
    }
  }
}
</style>
