<template>
  <view-wrapper :is-wide-body="true" ref="viewWrapper">
    <div class="top-section">
      <div class="d-flex flex-column header-section">
        <div class="prompt-group-dropdown">
          <div class="dropdown d-flex align-items-center">
            <span class="button-text">Compare AI Models for</span>
            <client-dropdown :shouldShowPeers="true" />
          </div>
        </div>
      </div>
    </div>

    <Response ref="responseComponent" :grouped-responses="groupedResponses" :no-data="noResponseData" :loading-data="loadingData" />
  </view-wrapper>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ViewWrapper from "@/views/ViewWrapper.vue";
import { ROUTE } from "@/constants";
import Response from "@/components/models-compare/Response.vue";
import ClientDropdown from "@/components/form-elements/ClientDropdown.vue";
import aiUtil from "@/utils/aiUtil.js";

export default {
  components: {
    ViewWrapper,
    Response,
    ClientDropdown,
  },

  data() {
    return {
      dateOptions: [],
      summaries: [],
      responses: [],
      route: ROUTE,
      prompts: [],
      noSummaryData: false,
      noResponseData: false,
      loadingData: false,
      initialLoadComplete: false, 
    };
  },
  methods: {
    ...mapActions("general", ["setView", "setShowProgress"]),
    ...mapActions("ai", [
      "setAiTag",
      "setAiEngineModel",
      "setAiEngineModels",
      "setSelectedTimeRange",
      "initializeAI",
      "setAppropriatePromptGroup",
      "setSelectedPrompt",
      "setSelectedTab",
      "toggleResponseOpen",
      "setOpenResponses",
    ]),
    selectTab(tab) {
      this.setSelectedTab(tab);
    },

    async fetchData(promptGroupId, selectedDateRange) {
      this.loadingData = true;
      this.setShowProgress(true);

      try {
        const client_id = this.userInfo?.client?.client_id;

        if (client_id) {
          const responsesResult = await this.$api.mongoGetResponsesByPromptGroup(promptGroupId, selectedDateRange, client_id);
          this.responses = responsesResult?.data || [];
          this.noResponseData = !this.responses.length;
        } else {
          console.error("Client ID is not available");
          this.noResponseData = true;
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        this.noResponseData = true;
      } finally {
        this.setShowProgress(false);
        this.adjustWideBodyWidth(); 
        this.loadingData = false;
      }
    },

    async fetchDataIfNeeded() {
      if (this.aiPromptGroup?.id && this.selectedDateRange) {
        this.fetchData(this.aiPromptGroup.id, this.selectedDateRange);
      }
    },

    toggleOpenAll() {
      this.setOpenResponses(this.isAllOpen ? [] : [...Array(this.groupedResponses?.[0]?.responses?.length).keys()]);
    },
    adjustWideBodyWidth() {
      this.$nextTick(() => {
        const responseComponent = this.$refs.responseComponent;
        if (responseComponent) {
          const width = responseComponent.$el.scrollWidth;
          this.$refs.viewWrapper.adjustWideBodyWidth(width);
        }
      });    
    }    
  },
  watch: {
    aiPromptGroup() {
      if (this.initialLoadComplete) {
        this.fetchDataIfNeeded();
      }
      this.setOpenResponses([0]);
    },
    selectedDateRange(currentValue, previousValue) {
      if (previousValue && this.aiPromptGroup?.id) {
        this.fetchData(this.aiPromptGroup.id, currentValue);
      }
    },
  },
  computed: {
    ...mapGetters("ai", {
      aiPromptGroups: "aiPromptGroups",
      aiPromptGroup: "aiPromptGroup",
      aiEngineModels: "aiEngineModels",
      selectedDate: "selectedDate",
      aiEngineModel: "aiEngineModel",
      selectedTab: "selectedTab",
      openResponses: "openResponses",
      userInfo: "userInfo"
    }),
    selectedDateRange() {
      return [this.selectedDate, this.selectedDate];
    },
    promptGroupsWithPeers() {
      return this.aiPromptGroups.flatMap(aiUtil.promptGroupToOptionsWithPeers);
    },

    filteredSummaries() {
      return this.summaries.filter(summary => summary.ai_prompt_group_id === this.aiPromptGroup.id);
    },

    groupedSummaries() {
      const summaries = this.filteredSummaries;
      const grouped = [];

      summaries.forEach((summary) => {
        const date = this.$dateTime.fromISO(summary.datetime).toFormat("MM/dd/yyyy");
        const key = date + "-" + summary.model_type;

        const existingGroup = grouped.find((group) => group.key === key);

        if (existingGroup) {
          existingGroup.summaries.push(summary);
        } else {
          const newGroup = {
            key: key,
            sortKey: summary.datetime,
            summaries: [summary],
          };
          grouped.push(newGroup);
        }
      });

      grouped.sort((a, b) => a.sortKey.localeCompare(b.sortKey));

      return grouped;
    },

    filteredResponses() {
      return this.responses.filter(response => response.ai_prompt_group_id === this.aiPromptGroup.id);
    },


    sortedFilteredResponses() {
      const responseCopy = [...this.filteredResponses];
      responseCopy.sort((a, b) => {
        const dateA = new Date(a.datetime);
        const dateB = new Date(b.datetime);

        return dateB - dateA;
      });

      return responseCopy;
    },
    groupedResponses() {
      const responses = this.sortedFilteredResponses;
      const grouped = [];

      responses.forEach((response) => {
        const date = this.$dateTime.fromISO(response.datetime).toFormat("MM/dd/yyyy");
        const key = date + "-" + response.model_type;

        const index = grouped.findIndex((group) => group.key === key);

        if (index === -1) {
          grouped.push({ key, sortKey: response.datetime, responses: [response] });
        } else {
          grouped[index].responses.push(response);
        }
      });

      grouped.sort((a, b) => a.sortKey.localeCompare(b.sortKey));

      return grouped;
    },

    isAllOpen() {
      return this.groupedResponses?.[0]?.responses?.length === this.openResponses.length;
    },
  },

  async mounted() {
    this.setView("modelsCompare");
    await this.initializeAI({ notifier: this.$notifier });
    this.fetchDataIfNeeded();
    this.initialLoadComplete = true; // Set the flag to true after initial fetch
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_mixins.scss";

.headers-wrapper {
  display: inline-flex;
  gap: var(--card-spacing);
}

.top-section {
  position: sticky;
  left: var(--gutter-width);
  max-width: max-content;
  z-index: calc(var(--z-sticky-baseline) + 4);

  .header-section {
    margin-bottom: var(--component-spacing-v);
  }

  .reputation-title {
    @include title-styles;
    font-size: 14px;
  }

  .prompt-group-dropdown {
    .dropdown {
      @include title-styles;
      font-size: 32px;
      gap: 1rem;
    }
  }

  .button-text {
    color: #545f71;
  }
}

</style>
