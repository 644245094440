<template>
  <div class="response-item">

    <template v-if="response.content">

      <Tags v-if="showTags" :response="response" @tagHover="handleTagHover" :routeback="routeback" class="tags"
        :shouldHighlightOnHover="true" />

      <span class="content" :class="{ 'show-more': showMore }" v-html="formattedAndHighlightedContent">
      </span>

      <span v-if="canExpand" class="show-content" @click="toggleShowMore">
        {{ showMore ? "Show Less" : "Show More" }}
      </span>

      <sources :prompts="[response]" />


    </template>

    <span v-else>
      <div>
        No response received from {{ modelType }} for this prompt.
      </div>
    </span>
  </div>
</template>

<script>
import Tags from "@/components/form-elements/tags/index.vue";
import { AiModelDisplayNames } from "@/constants";
import Sources from "@/components/form-elements/Sources.vue";
import { mapGetters } from "vuex";
import aiUtil from "@/utils/aiUtil.js";
import useRouteHelper from "@/composables/useRouteHelper";
import { applyBardFormatting, applyAIOverviewFormatting, applyChatGPTFormatting, applyCoPilotFormatting } from "@/utils/formattingUtils.js";

export default {
  props: ["response", "routeback"],
  data() {
    return {
      CONTENT_MAX: 310,
      showMore: false,
      highlightedContent: "",
      highlightTag: null,
    };
  },
  components: {
    Tags,
    Sources
  },
  computed: {
    ...mapGetters("ai", {
      neutralThreshold: "neutralThreshold",
      showSentiment: "showSentiment",
      showTags: "showTags",
    }),
    canExpand() {
      return this.response?.content?.length > this.CONTENT_MAX;
    },
    modelType() {
      return AiModelDisplayNames[this.response.model_type];
    },
    formattedAndHighlightedContent() {
      let formattedContent = aiUtil.stylizeTextBasedFormatting(this.highlightedContent);
      formattedContent = this.applyModelSpecificFormatting(formattedContent);
      return this.highlightMatchingTag(formattedContent);
    }
  },
  methods: {
    handleTagHover(tag) {
      this.highlightTag = tag;
    },
    highlightMatchingTag(prompt) {
      // Copy the content to avoid modifying the original prompt
      let highlightedContent = prompt;

      if (this.highlightTag?.phrase) {
        const regex = new RegExp(aiUtil.escapeRE(this.highlightTag.phrase), "gi");
        highlightedContent = highlightedContent.replace(
          regex,
          (match) => `<span class="matching-phrase">${match}</span>`
        );
      }

      return highlightedContent;
    },
    applyModelSpecificFormatting(content) {
      switch (this.response.model_type) {
        case 'bard':
          return applyBardFormatting(content);
        case 'chatgpt':
          return applyChatGPTFormatting(content);
        case 'bingai':
          return applyCoPilotFormatting(content);
        case 'sge':
          return applyAIOverviewFormatting(content);
        default:
          return content;
      }
    },

    toggleShowMore() {
      this.showMore = !this.showMore;
    },
    highlightMatchingPhrases() {
      const content = this.response.content;
      const sentiments = this.response.sentiments;
      this.highlightedContent = content;

      if (!content || !sentiments) return;

      if (this.showSentiment) {

        // Filter out null or undefined sentiments
        const validSentiments = sentiments.filter(sentiment => sentiment !== null && sentiment !== undefined);

        // Sort phrases by length in descending order
        validSentiments.sort((a, b) => b.phrase.length - a.phrase.length);

        validSentiments.forEach(phrase => {
          if (phrase !== null) {
            const regex = new RegExp(aiUtil.escapeRE(phrase.phrase), "gi");
            this.highlightedContent = this.highlightedContent.replace(
              regex,
              `<span class="${aiUtil.getSentiment(phrase.score, this.neutralThreshold)}-text">$&</span>`
            );
          }
        });
      }

    }
  },
  mounted() {
    this.highlightMatchingPhrases();
  },
  watch: {
    showSentiment() {
      this.highlightMatchingPhrases();
    },
    neutralThreshold() {
      this.highlightMatchingPhrases();
    },
    response() {
      this.highlightMatchingPhrases();
    },
  },
  setup() {
    const { routeTo } = useRouteHelper();
    return {
      routeTo,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_mixins.scss";
@import "@/scss/icons.scss";


.response-item {
  overflow: hidden;
  color: var(--grey8);
  @include body3;

  display: flex;
  flex-direction: column;
  gap: 0.75rem;

  .show-content {
    cursor: pointer;
    color: var(--primary-blue5);
    text-align: left;
    @include link1-style;
  }

  .content {
    max-height: 13rem;
    overflow: hidden;
    transition: max-height 0.0s ease-out;

    &.show-more {
      max-height: 1000rem;
    }
  }

  :deep(.negative-text) {
    background-color: #ffa5a5;
  }

  &.response-item-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    width: 100%;

    .model-type {
      color: var(--grey9);
      @include h4-style;
    }
  }

  .domain-container {
    display: flex;
    flex-wrap: wrap;

    .label {
      @include h4-style;
      color: var(--grey9);
      margin: auto 8px auto 0;
    }

    .domain {
      padding: 4px 8px;
      align-items: center;
      gap: 8px;
      margin-right: 8px;
      border-radius: 20px;
      background: var(--primary-blue2);

      span {
        color: var(--primary-blue5);
        @include body3;
      }
    }
  }

  :deep(.matching-phrase) {
    background-color: yellow !important;
    transition: background-color 0.3s ease;
  }
}
</style>