<template>
  <view-wrapper :is-wide-body="true" ref="viewWrapper">
    <div class="top-section">
      <div class="d-flex flex-column header-section">
        <div class="prompt-group-dropdown">
          <div class="dropdown d-flex align-items-center">
            <span class="button-text">Compare Peers to</span>
            <client-dropdown :shouldShowPeers="false" :greyOutNoPeers="true" />
          </div>
        </div>
      </div>
    </div>

    <div class="multiselect-container">
      <label for="aiPeers">Select Peers/Competitors</label>
      <MultiSelect :options="peers" valueProp="ai_peer_prompt_group_id" labelProp="ai_prompt_group"
        noData="No Peers Available" :value="peers ? selectedPeers : []" @input="updateSelectedPeers" />
    </div>

    <Response ref="responseComponent" :grouped-responses="groupedResponses" :no-data="noResponseData" :loading-data="loadingData" />
  </view-wrapper>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ViewWrapper from "@/views/ViewWrapper.vue";
import { ROUTE } from "@/constants";
import Response from "@/components/peer-compare/Response.vue";
import ClientDropdown from "@/components/form-elements/ClientDropdown.vue";
import MultiSelect from "@/components/form-elements/MultiSelect.vue";
import { _ } from "vue-underscore";

export default {
  components: {
    ViewWrapper,
    Response,
    ClientDropdown,
    MultiSelect,
  },

  data() {
    return {
      dateOptions: [],
      summaries: [],
      responses: [],
      route: ROUTE,
      prompts: [],
      selectedPeers: [],
      noSummaryData: false,
      loadingData: false,
      noResponseData: true,
      initialLoadComplete: false, 
    };
  },
  computed: {
    ...mapGetters("ai", {
      aiPromptGroup: "aiPromptGroup",
      aiPromptGroups: "aiPromptGroups",
      selectedDate: "selectedDate",
      aiEngineModel: "aiEngineModel",
      userInfo: "userInfo"
    }),
    peers() {
      const peers = this.aiPromptGroup?.peers || [];
      return [...peers];
    },
    filteredSummaries() {
      return this.summaries.filter((summary) => {
        return summary.model_type === this.aiEngineModel.model_type;
      });
    },

    groupedSummaries() {
      const summaries = this.filteredSummaries;
      const grouped = [];

      summaries.forEach((summary) => {
        const date = this.$dateTime.fromISO(summary.datetime).toFormat("MM/dd/yyyy");
        const key = date + "-" + summary.model_type + "-" + summary.ai_prompt_group_id;

        const existingGroup = grouped.find((group) => group.key === key);

        if (existingGroup) {
          existingGroup.summaries.push(summary);
        } else {
          const newGroup = {
            key: key,
            summaries: [summary],
          };
          grouped.push(newGroup);
        }
      });

      grouped.sort((a, b) => b.key.localeCompare(a.key));

      return grouped;
    },

    filteredResponses() {
      return this.responses.filter((response) => {
        return response.model_type === this.aiEngineModel.model_type;
      });
    },
    sortedFilteredResponses() {
      const responseCopy = [...this.filteredResponses];

      responseCopy.sort((a, b) => {
        const dateA = new Date(a.datetime);
        const dateB = new Date(b.datetime);

        return dateB - dateA;
      });

      return responseCopy;
    },
    groupedResponses() {
      const responses = this.sortedFilteredResponses;
      const grouped = [];

      responses.forEach((response) => {
        const date = this.$dateTime.fromISO(response.datetime).toFormat("MM/dd/yyyy");
        const key = date + "-" + response.model_type + "-" + response.ai_prompt_group_id;

        const index = grouped.findIndex((group) => group.key === key);

        if (index === -1) {
          grouped.push({ key, responses: [response] });
        } else {
          grouped[index].responses.push(response);
        }
      });

      grouped.sort((a, b) => b.key.localeCompare(a.key));

      return grouped;
    },
    uniqueAiPrompts() {
      const responses = this.groupedResponses;
      const aiPrompts = _.chain(responses)
        .map((group) => group.responses) // Extract 'responses' array from each group
        .flatten() // Flatten the array of arrays
        .pluck("ai_prompt") // Extract 'ai_prompt' property from each object
        .compact() // Remove null or undefined values
        .uniq() // Get unique values
        .value(); // Extract the final result from the chain

      return aiPrompts;
    },
    promptGroupIdList() {
      return this.aiPromptGroup.id +
        (this.selectedPeers.length ? "," + this.selectedPeers.map(peer => peer.ai_peer_prompt_group_id).join(",") : "");
    },
  },
  methods: {
    ...mapActions("general", ["setView", "setShowProgress"]),
    ...mapActions("ai", [
      "setAiPromptGroup",
      "initializeAI",
      "setAppropriatePromptGroup",
    ]),
    async fetchData() {
      this.summaries = [];
      this.responses = [];
      this.noSummaryData = false;
      this.noResponseData = false;
      this.loadingData = true;

      this.setShowProgress(true);

      try {
        const client_id = this.userInfo?.client?.client_id;

        if (!client_id) {
          console.error("Client ID is not available");
          this.noSummaryData = true;
          this.noResponseData = true;
          return;
        }

        const [responsesResult] = await Promise.all([
          this.$api.mongoGetResponsesByPromptGroupForDate(this.promptGroupIdList, this.selectedDate, client_id),
        ]);

        if (responsesResult && responsesResult.data) {
          const allResponses = responsesResult.data;
          if (!allResponses.length) {
            this.noResponseData = true;
          }
          const selectedGroupResponses = allResponses.filter(
            (response) => response.ai_prompt_group_id === this.aiPromptGroup.id
          );
          const otherResponses = allResponses.filter(
            (response) => response.ai_prompt_group_id !== this.aiPromptGroup.id
          );
          this.responses = [...selectedGroupResponses, ...otherResponses];
        } else {
          this.noResponseData = true;
          console.error("Did not receive responses data");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        this.noResponseData = true;
      } finally {
        this.setShowProgress(false);
        this.loadingData = false;
        this.adjustWideBodyWidth(); 
      }
    },


    updateSelectedPeers(value) {
      this.selectedPeers = value;
      if (value.length) {
        this.fetchData();
      }
    },
    adjustWideBodyWidth() {
      this.$nextTick(() => {
        const responseComponent = this.$refs.responseComponent;
        if (responseComponent) {
          const width = responseComponent.$el.scrollWidth;
          this.$refs.viewWrapper.adjustWideBodyWidth(width);
        }
      });    
    }    
  },
  watch: {
    async aiPromptGroup() {
      if (this.initialLoadComplete) {
        this.updateSelectedPeers(this.peers);
      }
    },

    selectedDate(currentValue, previousValue) {
      if (previousValue && this.initialLoadComplete) {
        this.fetchData();
      }
    },
  },
  async mounted() {
    this.setView("peersCompare");
    await this.initializeAI({ notifier: this.$notifier }).then(() => {
      if (this.aiPromptGroup?.parent_prompt_group_id) {
        const promptGroup = this.aiPromptGroups.find(promptGroup => promptGroup.id === this.aiPromptGroup.parent_prompt_group_id);
        this.setAiPromptGroup(promptGroup ? promptGroup : null);
      } else {
        this.updateSelectedPeers(this.peers);
      }
    });
    this.initialLoadComplete = true; // Set the flag to true after initial fetch
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_mixins.scss";

.headers-wrapper {
  display: inline-flex;
  gap: var(--card-spacing);
}

.top-section {
  position: sticky;
  left: var(--gutter-width);
  max-width: max-content;
  z-index: calc(var(--z-sticky-baseline) + 4);

  .header-section {
    margin-bottom: var(--component-spacing-v);
  }

  .prompt-group-dropdown {
    
    .dropdown {
      @include title-styles;
      font-size: 32px;
      gap: 1rem;
    }

    .button-text {
      color: #545f71;
      white-space: nowrap;
    }
  }
}

.multiselect-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: max-content;
  position: sticky;
  left: var(--gutter-width);
  z-index: calc(var(--z-sticky-baseline) + 3);

  label {
    align-self: stretch;
    color: var(--grey7);

    @include body4;
  }

  .no-peers {
    color: var(--grey7);
    @include body3;
  }
}
</style>
