<template>
  <div class="fb-main-sidebar">
    <app-logo theme="light" size="small" class="app-logo" />
    <PanelMenu :model="items">
      <template #item="{ item }">
        <div
             class="item-container"
             :class="{
              disabled: isDisabled(item),
              highlight: isCurrent(item),
              separator: item.separator,
            }"
             @click="manageClick(item)">
          <div class="d-flex align-items-center">
            <span v-if="menuShow" class="icons" :class="[item.icon, { dark: isCurrent(item) }]" />
            <span v-else class="icons" :class="[item.icon, { dark: isCurrent(item) }]" />

            <router-link :to="item.route" class="ms-2">{{ item.label }}</router-link>
          </div>

          <span :class="[{ 'icons arrow-right': isCurrent(item) }]" />
        </div>
      </template>
    </PanelMenu>

    <div class="profile">
      <div class="negative-sensitivity">
        <label for="negative-sensitivity" class="form-label">Negative Sensitivity</label>
        <input v-model="neutralThreshold" type="range" class="form-range" min="0" max="10" step="1"
               id="negative-sensitivity">
        <div class="ticks">
          <div v-for="(value, index) in [...Array(11)]" :key="index" class="tick"
               :class="{ on: index == neutralThreshold }">
            {{ index }}</div>
        </div>
      </div>

      <div class="user-initials-circle">
        <button type="button" class="btn btn-default btn-circle dropdown-toggle" id="profileMenuId"
                data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">{{ userInitials }}</button>
        <ul class="dropdown-menu profile-dropdown" aria-labelledby="profileMenuId">
          <li>
            <a class="dropdown-item" href="#" @click="logout()">Logout</a>
          </li>
        </ul>
      </div>
      <span class="user-name">{{ userName }}</span>
    </div>
  </div>
</template>

<script>
import PanelMenu from "primevue/panelmenu";
import { mapGetters, mapActions } from "vuex";
import useRouteHelper from "@/composables/useRouteHelper";
import storage from "@/utils/storageUtil";
import { sidebarItems } from "@/constants";
import AppLogo from "@/components/AppLogo";
import router from '@/router';

export default {
  name: "SideMenu",
  props: {
    showControl: Boolean,
    isEdit: Boolean,
  },
  data() {
    return {
      show: true,
      items: sidebarItems,
    };
  },
  components: {
    PanelMenu,
    AppLogo,
  },
  computed: {
    ...mapGetters("general", { menuShow: "menuShow" }),
    ...mapGetters("ai", { userInfo: "userInfo" }),
    neutralThreshold: {
      get() {
        return this.$store.state.ai.neutralThreshold;
      },
      set(value) {
        this.setNeutralThreshold(value);
      }
    },
    userName() {
      const { first_name, last_name } = this.userInfo;

      if (first_name && last_name) {
        return `${first_name} ${last_name}`;
      } else {
        return "";
      }
    },
    userInitials() {
      const { first_name, last_name } = this.userInfo;

      if (first_name && last_name) {
        const firstNameInitial = first_name.charAt(0).toUpperCase();
        const lastNameInitial = last_name.charAt(0).toUpperCase();
        return `${firstNameInitial}${lastNameInitial}`;
      } else {
        return "";
      }
    },
  },
  methods: {
    ...mapActions("general", ["setMenuShow"]),
    ...mapActions("ai", ["setNeutralThreshold"]),

    buttonClick(type) {
      this.$emit("handleButtonClick", type);
    },
    isDisabled(item) {
      return item.disabled;
    },
    isCurrent(item) {
      return "route" in item && this.isRoute(item.route);
    },
    manageClick(item) {
      if (item.disabled) {
        return;
      }

      if (this.isCurrent(item)) {
        return;
      }

      if (item.legacy) {
        this.forwardUrl(item.route);
      } else {
        this.routeTo(item.route);
      }
    },
    forwardUrl(menuItem) {
      let url = this.client && this.client.clientid ? "#clientid=" + this.client.clientid : "";
      url += this.start ? "&start=" + this.start : "";
      url += this.end ? "&end=" + this.end : "";
      url += this.resultset ? "&resultset=" + this.resultset : "";
      url += this.ctid ? "&ctid=" + this.ctid : "";
      url += "&view=" + (menuItem === "results" ? "timeline" : menuItem);

      window.location.href = `/${url}`;
    },
    logout() {
      try {
        this.$api
          .logout()
          .then(response => {
            const token = response.data.access_token;
            localStorage.setItem('token', token);             
          })
          .finally(() => {
            delete this.$route.query.clientid;
            router.push('/login');
          })
      } catch (e) {
        console.error(e);
      }
    },
  },
  setup() {
    const { routeTo, isRoute } = useRouteHelper();
    return {
      routeTo,
      isRoute,
    };
  },
  mounted() {
    const menuShow = JSON.parse(storage.get("menuShow") || false);
    this.setMenuShow(menuShow);
  },
};
</script>

<style lang="scss">
@import "@/scss/_mixins.scss";
@import "@/scss/_icons.scss";

.fb-main-sidebar {
  background: var(--grey1);
  margin-left: 0;
  position: relative;
  border: 0 !important;

  &.hide {
    width: var(--sidebar-minimized-width) !important;
  }

  .p-panelmenu {
    width: var(--side-menu-width);
    border-radius: 1.25rem;
    background: var(--dark-blue);
    height: calc(100vh - 1rem);
    margin: 0.5rem;

    * {
      box-shadow: none !important;
    }

    .p-panelmenu-panel {
      &:first-child {
        padding-top: 6rem;
      }
    }

    .p-panelmenu-header {
      &.p-hightlight {
        border: 0;
      }

      padding: var(--side-menu-item-spacing-v) var(--side-menu-item-spacing-h);
      border-radius: 3.75rem;
    }

    .p-panelmenu-header-content {
      border: 0;
      background: var(--dark-blue) !important;
      font-size: 14px;
      font-weight: 600;
      border-color: transparent !important;
      display: flex;

      .item-container {
        display: flex;
        justify-content: space-between;
        width: var(--side-menu-item-width);
        padding: var(--side-menu-item-padding-v) var(--side-menu-item-padding-h);
        align-items: center;
        cursor: pointer;

        .icons {
          padding: 0 10px;

          &:first-child {
            min-width: 17px;
          }
        }

        a {
          color: var(--menu-title);
          text-decoration: none;
          margin: auto 0;
          overflow: hidden;
          white-space: nowrap;
        }

        .menu-closed-arrow {
          margin: auto 0;
          margin-left: 8px;
        }

        &.disabled {
          opacity: 0.5;
        }

        &:hover {

          a,
          span {
            color: white;
            filter: brightness(180%);
          }
        }

        &.highlight {
          background-color: white !important;
          border-radius: 3.75rem;

          a,
          span {
            color: var(--grey8) !important;
          }

          span {
            filter: brightness(180%);
          }
        }
      }
    }
  }

  .negative-sensitivity {
    position: absolute;
    bottom: 6rem;
    color: white;
    width: 70%;
    @include body3;

    label {
      margin: 0;
    }

    input::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
    }

    input::-moz-range-thumb {
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
    }

    .ticks {
      display: flex;
      justify-content: space-between;
      width: 110%;
      margin-left: -.4rem;

      .tick {
        visibility: hidden;
        background-color: white;
        color: black;
        font-weight: bold;
        margin: auto;
        border-radius: 4px;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 1);
        padding: .2rem 0;
      }
    }

    input:active~.ticks .tick.on {
      visibility: visible;
      padding: .2rem .5rem;
    }
  }

  .profile {
    margin-top: -5rem;
    display: flex;
    margin-left: calc(var(--side-menu-item-spacing-h) + var(--side-menu-item-padding-h));

    .user-name {
      color: white;
      align-self: center;
      @include body3;
      font-weight: 500;
      margin-left: 0.5rem;
    }

    .user-initials-circle {
      width: 3rem;
      height: 3rem;
      border-radius: 3rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #d48c08;

      button {
        color: white;
        @include body3;
        font-weight: bold;
      }

      .btn-default.dropdown-toggle::after {
        content: none;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.app-logo {
  position: absolute;
  left: var(--side-menu-item-spacing-h);
  top: 1.5rem;
}
</style>
