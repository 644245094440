import storage from "@/utils/storageUtil";

const state = () => ({
    show_progress: false,
    in_progress: false, // this is used to set a flag but not show an in progress model
    menuShow: true,
    view: '',
})

const getters = {
    showProgress: state => state.show_progress,
    inProgress: state => state.in_progress,
    menuShow: state => state.menuShow,
    view: state => state.view,
}

const actions = {
    setShowProgress({ commit }, payload) {
        commit('SET_SHOW_PROGRESS', payload);
    },
    setInProgress({ commit }, payload) {
        commit('SET_IN_PROGRESS', payload);
    },    
    setMenuShow({ commit }, show) {
        commit('SET_MENU_SHOW', show);
    },
    setView({ commit }, payload) {
        commit('SET_VIEW', payload);
    },    
   
}

const mutations = {
    SET_SHOW_PROGRESS(state, payload) {
        state.show_progress = payload
    },
    SET_IN_PROGRESS(state, payload) {
        state.in_progress = payload
    },    
    SET_MENU_SHOW(state, payload) {
        state.menuShow = payload;
        storage.set("menuShow", payload);
    },
    SET_VIEW(state, payload) {
        state.view = payload;
    },    
     
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
