<template>
  <div>

    <div v-if="noData" class="no-data">There is no data for the selected date range.</div>
    <div v-else-if="!groupedResponses?.length && !loadingData" class="no-data">There is no data for the selected model.
    </div>

    <div class="justify-content-between d-flex mt-3">
      <div class="response-view ">
        <response-card v-for="(groupedResponse, index) in orderByModel(groupedResponses)" :key="groupedResponse.key"
          :routeback="routeback" :grouped-response="groupedResponse" class="response-card fit-card" :class="{
      'has-4': groupedResponses.length >= 4,
      'has-5': groupedResponses.length >= 5,
      'scroll-into-view': isTimeline && index === groupedResponses.length - 1
    }" />
      </div>
    </div>

  </div>
</template>

<script>
import ResponseCard from "@/components/card-elements/ResponseCard.vue";
import { AiModelDisplayNames } from "@/constants";
import { ROUTE } from "@/constants";

export default {
  props: ["groupedResponses", "noData", "loadingData", "routeback", "isTimeline"],
  data() {
    return {
      route: ROUTE,
    };
  },
  components: {
    ResponseCard,
  },
  methods: {
    orderByModel(groupedResponses) {
      const modelArr = Object.keys(AiModelDisplayNames)
      const ordered = groupedResponses.sort((a, b) => {
        return modelArr.indexOf(a.responses[0].model_type) - modelArr.indexOf(b.responses[0].model_type);
      });
      return ordered;
    },
  },

};
</script>

<style lang="scss" scoped>
@import "@/scss/_mixins.scss";
@import "@/scss/icons.scss";

.response-view {
  display: flex;
  margin-top: 1rem;
  gap: var(--card-spacing);

  &>div {
    flex: 1;
    min-width: 0;
  }

  .response-card {
    min-width: 15rem;
    max-width: 25rem;
  }

  .fit-card {
    --cards-to-fit: 3;
    --indicate-more-buffer: 0rem;

    &.has-4 {
      --cards-to-fit: 4;
    }

    &.has-5 {
      --indicate-more-buffer: 1rem;
    }

    width: calc((100vw - var(--gutter-width) - var(--side-menu-width) - ((var(--card-spacing) * (var(--cards-to-fit) - 1))) - 3rem - var(--indicate-more-buffer)) / var(--cards-to-fit));
  }
}

.no-data {
  position: sticky;
  margin-top: 1rem;
  left: var(--gutter-width);
  max-width: max-content;
}
</style>
